.testTables {
    width: 100%;
    padding: 20px 0px;
    border-spacing: 0ch;
  }
  .testTables tr{
    width: 100%;
display: inline-table;
table-layout: fixed;
  }
  .testTables thead{
    width: 100%;
    display: inline-table;
    table-layout: fixed;
  }
  .testTables tbody{
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
    width: 100%;
    height: 57vh;
    scrollbar-width: none;
  }
  .note-text{
    color:red;
    font-weight: 600;
  }
  .testTables tbody::-webkit-scrollbar { width: 0 !important }
  .testTables tbody { overflow: -moz-scrollbars-none; }
/* -ms- (Internet Explorer +10): */
.testTables tbody { -ms-overflow-style: none; }
  .testTables .tableHeadRow th:first-child {
    border-top-left-radius: 10px;
  }
  .testTables .tableHeadRow:first-child th:last-child {
    border-top-right-radius: 10px;
  }
  .testTables .tableHeadRow {
    background: #f6f6fc;
  }
  .testTables .tableHeadRow .tableHead {
    text-align: left;
    font-family: var(--semiBold);
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
    letter-spacing: 5%;
    color: #a9a8c3;
    padding: 14px 20px;
    padding-left: 40px;
  }
  .testTables .tableHeadRow .tableHead .dropArrow svg {
    align-items: center;
    vertical-align: middle;
    padding-left: 10px;
    box-sizing: border-box;
    width: 34px;
  }
  .testTables .tableDataRow td {
    border-top: 1px solid #e1e1ff;
    border-bottom: 1px solid #e1e1ff;
  }
  .testTables .tableDataRow td:first-child {
    border-left: 1px solid #e1e1ff;
  }
  .testTables .tableDataRow td:last-child {
    border-right: 1px solid #e1e1ff;
  }
  .testTables .tableDataRow:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  .testTables .tableDataRow:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
  .testTables .tableDataRow .tableData {
    font-family: var(--semiBold);
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    vertical-align: center;
    letter-spacing: 5%;
    color: #45417b;
    padding: 16px 20px;
    padding-left: 40px;
  }
  .testTables .tableDataRow .tableData p{
    margin-bottom: 0 !important;
  }
  .testTables .tableDataRow .tableData button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0px;
  }
  .testTables .tableDataRow .tableData button svg {
    height: 22px;
  }
  .TablePagination {
    position: absolute;
    bottom: 20px;
    right: 42px;
    display: inline;
    align-content: center;
  }
  .TablePagination button {
    background: none;
    outline: none;
    color: var(--secondary-color);
    border: none;
    font-family: var(--semiBold);
    font-size: 18px;
    padding: 0;
    vertical-align: middle;
    cursor: pointer;
  }
  .TablePagination span {
    padding: 0px 4px;
    color: var(--deep-blue);
    vertical-align: middle;
    font-size: 18px;
  }
  .TablePagination .pageCount {
    color: var(--deep-blue);
  }
  .TablePagination .pageCount .currentPage {
    color: var(--orange);
    font-family: var(--semiBold);
  }
  .TablePagination .pageInput {
    -webkit-appearance: none;
    outline: 0;
    font-size: 0.9em;
    font-weight: 600;
    border-radius: 6px;
    background: #e1e1ff;
    border: 1px solid #e1e1ff;
    padding: 0.4em 1em;
    color: #000;
    text-align: center;
  }
  