@media (max-width: 1500px) {
  .chat-attachment{
    bottom: 8px !important;
  }
}
@media (max-width: 1280px) {
  .filter_span {
    letter-spacing: 0;
  }
  .filter_span {
    margin-top: 1px;
  }
  .all_products {
    margin-bottom: 0;
  }
  .side_add_cont {
    position: relative;
    flex-direction: row;
    margin: 30px 0px;
    right: auto;
    max-width: 100%;
  }
  .side_add_cont img {
    margin: 0 30px 0 0;
  }
  .position-relative .col-md-9 {
    width: 100%;
  }
  .container.position-relative .side_add_cont {
    width: auto;
  }
  .container.position-relative .side_add_cont img {
    max-width: 200px;
    margin: 0 15px 15px 0;
  }
  .main-product.collection-otr {
    margin-bottom: 50px;
  }
  .container.position-relative > .row {
    margin-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .timeline {
    width: 680px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .direction-l {
    position: relative;
    width: 320px;
    float: left;
    text-align: right;
  }
  .direction-r {
    position: relative;
    width: 320px;
    float: right;
    text-align: left;
  }
}
@media (max-width: 1199px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 100%;
  }
  .topbar .col_6.col-md-6:last-child {
    padding-right: 1rem;
  }
  nav.navbar .navbar-nav .nav-link,
  .navbar-nav > li.nav-item a.nav-link {
    padding: 11px 10px;
    font-size: 13px;
  }
  .facts .facts_box span {
    font-size: 20px;
  }
  .heading {
    font-size: 40px;
    line-height: 45px;
  }
  .dashboardtabs ul.nav.nav-tabs {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none;
    flex-wrap: nowrap;
  }
  .dashboardtabs ul.nav.nav-tabs::-webkit-scrollbar {
    display: none;
  }
  .dashboardtabs .tabs .nav-tabs .nav-item .nav-link {
    padding: 10px 40px;
  }
  .testTables .tableDataRow .tableData {
    font-size: 14px;
    line-height: initial;
  }
}
@media (max-width: 991px) {
  .testTables thead {
    width: 100%;
    display: table-header-group;
    table-layout: unset;
  }
  .testTables tbody {
    overflow-y: scroll;
    overflow-x: unset;
    display: table-row-group;
    width: unset;
    height: auto;
  }
  .testTables tr {
    width: 100%;
    display: table-row;
    table-layout: unset;
  }
  .testTables th,
  .testTables td {
    white-space: nowrap;
  }
  .testTables .tableHeadRow .tableHead {
    min-width: 150px;
  }
  .service_provider_box h3.text-center {
    font-size: 20px;
  }
  p {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  nav.navbar .navbar-brand img {
    /* max-width: 85px; */
    max-width: 150px;
    width: 120px;
  }
  nav.navbar ul.navbar-nav {
    align-items: center;
    margin: 15px 0;
  }
  nav.navbar .navbar-nav .nav-item {
    width: 100%;
  }
  nav.navbar .navbar-nav .nav-link {
    padding: 15px 0;
  }
  nav.navbar .navbar-nav .nav_btn_outer {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }
  nav.navbar .navbar-nav .nav-item.nav_btn {
    margin-left: 0;
    margin-top: 15px;
    margin-right: 0;
    width: 46%;
  }
  nav.navbar .navbar-nav .nav-item.nav_btn .nav-link {
    padding: 15px 17px;
    text-align: center;
  }
  .heading {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 55px;
  }
  .small_heading {
    font-size: 18px;
    margin-bottom: 20px;
  }
  header h1 {
    font-size: 45px;
    line-height: 55px;
  }
  .crop_process .crop_process_box img {
    width: 40px;
  }
  .crop_process .crop_process_box h5 {
    font-size: 16px;
    margin-left: 10px;
  }
  .buyer_seller h3 {
    font-size: 30px;
  }
  .service_provider a.btn.cstm_btn {
    margin-top: 80px;
  }
  .service_provider .service_provider_carousel .owl-nav button.owl-prev {
    margin-left: 0;
  }
  .service_provider .service_provider_carousel .owl-nav button.owl-next {
    margin-right: 0;
  }
  .about_us .about_us_content {
    padding-left: 0;
    margin-top: 35px;
  }
  .facts h4 {
    font-size: 35px;
  }
  .facts .facts_box {
    font-size: 45px;
    line-height: 50px;
  }
  .facts .facts_box span {
    font-size: 22px;
    line-height: initial;
  }
  .home_buy_seeds {
    margin: 80px auto;
  }
  .home_buy_seeds .left {
    width: 40%;
  }
  .home_buy_seeds .right {
    width: 60%;
  }
  .home_buy_seeds .owl-carousel.home_buy_seeds_carousel .owl-item {
    padding: 5px;
  }
  .home_buy_seeds .buy_seeds_box {
    padding: 8px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 14%);
  }
  .home_buy_seeds .buy_seeds_box .name {
    font-size: 16px;
    margin-top: 20px;
  }
  .home_buy_seeds .buy_seeds_box .price {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .home_buy_seeds .buy_seeds_box .fav_icon {
    right: 15px;
    font-size: 18px;
    top: 3px;
  }
  .testimonial_feeds .testimonials_carousel {
    margin-bottom: 50px;
  }
  .testimonial_feeds .feeds_carousel .owl-nav {
    top: -100px;
  }
  .testimonial_feeds .feeds_box_outer .feeds_box .img_sec img {
    width: 100%;
    min-width: 100px;
    max-width: 130px;
  }
  .testimonial_feeds .feeds_box_outer .feeds_box .content_sec {
    padding: 0 25px;
  }
  .testimonial_feeds .feeds_box_outer .feeds_box .content_sec h5 {
    font-size: 18px;
    line-height: 22px;
  }
  .newsletter form {
    margin-top: 35px;
  }
  .newsletter:before {
    top: auto;
    bottom: 140px;
  }
  footer a.footer_logo {
    display: block;
    margin-bottom: 35px;
  }
  .cstm_header {
    margin-bottom: 50px;
  }
  .about_sec .img_outer {
    margin: 30px auto;
  }
  .about_sec p {
    padding: 0;
    margin-top: 30px;
  }
  .mission_vision {
    padding: 80px 0;
  }
  .mission_vision h4 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .mission_vision img {
    max-width: 70px;
  }
  .all_products .filters .filter_box {
    padding: 20px 15px 15px 15px;
  }
  .all_products .filters .filter_box h5 {
    font-size: 18px;
  }
  .all_products .filters .filter_collapse .filter_collapse_item ul li a {
    padding: 10px 0;
    font-size: 14px;
  }
  .all_products .filters .filter_collapse .filter_collapse_item ul {
    padding: 0;
  }
  .all_products .filters .filter_collapse .filter_collapse_header {
    padding: 10px 0;
  }
  .all_products .filters .filter_collapse .filter_collapse_header:before {
    top: 11px;
  }
  .all_products .content .buy_seeds_box {
    margin-bottom: 30px;
  }
  .all_products .filters .filter_box .selector {
    padding-bottom: 10px;
  }
  .all_products .content .buy_seeds_box .name {
    font-size: 16px;
    margin-top: 20px;
  }
  .all_products .content .buy_seeds_box .average {
    font-size: 16px;
  }
  .all_products .content .buy_seeds_box .price {
    font-size: 16px;
  }
  .all_products .content .buy_seeds_box .fav_icon {
    right: 10px;
    font-size: 18px;
  }
  .browse_category_heading {
    font-size: 25px;
  }
  .browse_category_heading img {
    max-width: 40px;
  }
  .browse_category_carousel.owl-carousel .owl-nav {
    top: -70px;
  }
  .browse_category_carousel.owl-carousel .owl-nav button {
    height: 40px;
    width: 40px;
  }
  .browse_category_carousel .browse_category_box {
    padding: 30px 0;
  }
  .browse_category_carousel .browse_category_box .title {
    font-size: 18px;
  }
  .buyer_seller_cont {
    margin-bottom: 80px;
  }
  .buyer_seller_cont_outer {
    flex-direction: column;
    margin-bottom: 45px;
  }
  .buyer_seller_cont_outer .left {
    width: 100%;
  }
  .buyer_seller_cont_outer .right {
    width: 100%;
    padding: 0;
  }
  .buyer_seller_cont h4 {
    margin-top: 25px;
    font-size: 35px;
    line-height: 40px;
  }
  .service_provider_cont_box {
    flex-direction: column;
    padding: 30px;
  }
  .service_provider_cont_box .info {
    padding-left: 0;
  }
  .service_provider_cont_box img {
    max-width: 90px;
    max-height: 90px;
  }
  .service_provider_cont_box .info .name {
    font-size: 20px;
  }
  .service_provider_cont_box .info .rating {
    color: #fcd804;
    font-size: 12px;
  }
  .service_provider_cont_box .pay_hire {
    text-align: left;
    width: 100%;
    margin-top: 25px;
  }
  .service_provider_cont_box .pay_hire .price {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .service_provider_cont_box .pay_hire .cstm_btn {
    width: 100%;
  }
  .service_provider_cont {
    margin-bottom: 80px;
  }
  .product_details_cont .product_meta_info {
    padding-left: 0;
  }
  .product_details_cont .product_meta_info .name {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .product_details_cont .product_meta_info ul li {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .product_details_cont .product_meta_info .price {
    font-size: 16px;
  }
  .product_details_cont .prod_des h4 {
    font-size: 25px;
  }
  .product_details_cont .prod_des {
    margin-top: 80px;
  }
  .related_products {
    margin: 80px auto;
  }
  .related_products .buy_seeds_box {
    margin-bottom: 30px;
  }
  .product_details_cont .product_meta_info .price span {
    font-size: 20px;
  }
  .how_it_works_cont {
    padding-bottom: 80px;
  }
  .flag-wrapper h4 {
    font-size: 22px;
  }
  .faqs_cont {
    margin: 80px auto;
  }
  .faqs_cont .faqs_collapse {
    padding: 20px 35px;
  }
  .faqs_cont .faqs_collapse .faqs_collapse_header {
    font-size: 18px;
  }
  .faqs_cont .faqs_collapse .faqs_collapse_item {
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .contact_cont .contact_form {
    padding: 40px 40px;
  }
  .map {
    margin-bottom: 80px;
  }
  .map iframe {
    height: 450px;
  }
  .login_signup .left img {
    max-width: 350px;
  }
  .login_signup .right .heading {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .login_signup .right .form-group label {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .login_signup .right .form-group .form-control {
    height: 50px;
    font-size: 16px;
  }
  .login_signup .right .form-group {
    margin-bottom: 30px;
  }
  .login_signup .right .form-group .btn.cstm_btn {
    font-size: 16px;
    padding: 12px 0;
  }
  .other_sec h5 {
    font-size: 22px;
    margin-bottom: 25px;
  }
  .other_sec p {
    margin-bottom: 45px;
  }
  .other_sec {
    margin-bottom: 80px;
  }
  .service_provider_details_cont {
    margin-bottom: 80px;
  }
  .home_buy_seeds .buy_seeds_box .img_outer img {
    height: 200px;
  }
  .service-relate .col-lg-2 {
    width: 16.6%;
    margin: 15px 0;
  }
  .all_products .filters {
    width: 30%;
  }
  .image-gallery-slide-wrapper.left button.image-gallery-icon svg {
    height: 50px;
    width: 40px;
  }
  a.c-header-brand.mx-auto.d-lg-none.active {
    margin-left: 20px !important;
    display: none;
  }
  a.c-header-brand svg.c-icon.c-icon-custom-size {
    width: 150px !important;
    height: auto !important;
  }
  .testTables .tableDataRow td:last-child img {
    width: 15px !important;
    height: 15px !important;
  }
  .testTables .tableDataRow .tableData {
    font-size: 14px;
    line-height: 22px;
  }
  body .TablePagination {
    margin-top: 15px;
    -webkit-justify-content: unset;
    justify-content: unset;
}
  .testTables .tableDataRow .tableData,
  .testTables .tableHeadRow .tableHead {
    padding-left: 20px;
  }
  .profile-page {
    padding: 10px 0;
  }
  .dashboard_outer {
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .c-wrapper {
    margin-left: 0 !important;
    transform: translateX(300px);
  }
  .headingWrap h3.title {
    font-size: 16px;
  }
  .order-detail-grid {
    padding: 21px 0 !important;
  }
  .contract-grid {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-column-gap: 20px;
  }
  .contracts p {
    display: grid;
    grid-template-columns: 1fr 1fr;
    word-break: break-all;
    grid-column-gap: 20px;
  }
  .contracts p span {
    margin-left: 0;
  }
}

@media (min-width: 768px) {

  body .dashboard-container {
    padding-top: 115px !important;
  }
  .timeline {
    width: 660px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .timeline .hexa {
    left: -28px;
    right: auto;
    top: 0px;
  }

  .timeline .direction-l .hexa {
    left: auto;
    right: -28px;
  }

  .direction-l {
    position: relative;
    width: 310px;
    float: left;
    text-align: right;
  }

  .direction-r {
    position: relative;
    width: 310px;
    float: right;
    text-align: left;
  }

  .flag-wrapper {
    display: inline-block;
    padding-left: 40px;
  }
  .direction-l .flag-wrapper {
    padding-left: 0;
    padding-right: 40px;
  }
  .flag {
    font-size: 18px;
  }

  .direction-l .flag:after {
    left: auto;
    right: -16px;
    top: 50%;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: rgb(254, 254, 254);
    border-width: 8px;
  }

  .direction-r .flag:after {
    top: 50%;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: rgb(254, 254, 254);
    border-width: 8px;
    left: -8px;
  }

  .time-wrapper {
    display: inline;
    vertical-align: middle;
    margin: 0;
  }

  .direction-l .time-wrapper {
    float: left;
  }

  .direction-r .time-wrapper {
    float: right;
  }

  .time {
    padding: 5px 10px;
  }

  .direction-r .desc {
    margin: 1em 0 0 0.75em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service_provider_cont_box .info_outer {
    flex-direction: column;
  }
  .service_provider_cont_box .price {
    margin-top: 20px;
  }
  .service_provider_cont_box .info .rating span {
    font-size: 15px;
    margin-left: 0;
    display: flex;
    margin-top: 3px;
  }
}
@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size: 12px;
   
  }
  .button_grid{
    display: grid;
    grid-template-columns: 1fr;
    gap:10px;
  }
  .s-provider {
    padding: 20px 20px;
  }
  .dashboardtabs.scroll_div h3.m-4 {
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
  label.product-label.ml-3 {
    margin-left: 5px !important;
  }
  .PrivateSwitchBase-root-1 {
    padding: 0;
  }
  .card.service-card .w-100.row {
    width: auto !important;
  }
  .headingWrap {
    margin-top: 0;
  }
  .tab-content {
    margin-top: 1.5rem;
  }
  .dashboardtabs .tabs .nav-tabs .nav-item .nav-link {
    padding: 10px 20px;
    font-size: 18px;
  }
  .testTables .tableHeadRow .tableHead {
    min-width: 100px;
  }
  p {
    font-size: 15px;
    line-height: 23px;
  }
  .heading {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 45px;
  }
  .small_heading {
    font-size: 18px;
    margin-bottom: 15px;
  }
  header h1 {
    font-size: 36px;
    line-height: 42px;
  }
  header .header_box form {
    padding: 0 30px;
    margin-bottom: 30px;
  }
  .service_provider,
  .buyer_seller,
  .about_us,
  .testimonial_feeds,
  .crop_process,
  .facts,
  .newsletter {
    padding: 50px 0;
  }
  nav.navbar .navbar-nav .nav-item.nav_btn .nav-link {
    text-align: center;
    padding: 12px;
  }
  .crop_process .crop_process_box {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .crop_process .crop_process_box h5 {
    margin-top: 15px;
  }
  .crop_process .crop_process_box img {
    width: 50px;
  }
  .buyer_seller h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .service_provider_outer .service_provider_box h3 {
    font-size: 16px;
  }
  /* .service_provider_outer .service_provider_box img {
    max-width: 60px;
  } */
  .service_provider a.btn.cstm_btn {
    margin-top: 50px;
  }
  .about_us .small_heading {
    margin-bottom: 15px;
  }
  .facts h4 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .facts .facts_box {
    font-size: 35px;
    line-height: 35px;
    margin: 15px 0;
  }
  .facts .facts_box span {
    font-size: 18px;
    margin-top: 10px;
  }
  .facts .last_fact {
    margin-top: 40px;
  }
  .home_buy_seeds {
    margin: 15px auto;
    flex-direction: column;
  }
  .home_buy_seeds .left {
    width: 100%;
  }
  .home_buy_seeds .right {
    width: 100%;
    padding: 0;
    border: none;
    margin-top: 15px;
  }
  .testimonial_feeds .heading {
    margin-bottom: 45px;
  }
  .testimonial_feeds .testimonials_box .img_outer {
    margin-bottom: 20px;
  }
  .testimonial_feeds .feeds_carousel .owl-nav {
    top: -90px;
  }
  .testimonial_feeds .feeds_box_outer .feeds_box .content_sec {
    padding: 0 0 0 20px;
  }
  .testimonial_feeds .feeds_box_outer .feeds_box .content_sec h5 {
    font-size: 16px;
    line-height: 18px;
  }
  .newsletter h4 {
    font-size: 25px;
    margin-bottom: 25px;
  }
  .newsletter:before {
    top: auto;
    bottom: 120px;
    left: 0;
  }
  .mission_vision {
    padding: 50px 0;
    flex-direction: column;
  }
  .mission_vision .mission_vision_box {
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }
  .mission_vision h4 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .mission_vision .mission_vision_box:last-child {
    margin-bottom: 0;
  }
  .all_products {
    flex-direction: column;
  }
  .all_products .filters {
    width: 100%;
  }
  .all_products .content {
    width: 100%;
    padding-left: 0;
  }
  .all_products .filters .filter_box {
    padding: 25px 25px 15px 25px;
  }
  .all_products .filters .filter_collapse .filter_collapse_item ul {
    padding-left: 15px;
  }
  .all_products .filters .side_add {
    margin-bottom: 30px;
  }
  .browse_category {
    margin-bottom: 20px;
  }
  .browse_category_carousel .browse_category_box img {
    max-width: 100px;
  }
  .browse_category_carousel .browse_category_box .title {
    font-size: 16px;
  }
  .browse_category_heading img {
    max-width: 30px;
  }
  .browse_category_heading {
    font-size: 22px;
  }
  .browse_category_carousel {
    margin-bottom: 50px;
  }
  .browse_category_carousel.owl-carousel .owl-nav {
    top: -60px;
  }
  .browse_category_carousel.owl-carousel .owl-nav button {
    height: 32px;
    width: 32px;
  }
  .buyer_seller_cont {
    margin-bottom: 50px;
  }
  .buyer_seller_cont h4 {
    font-size: 30px;
    line-height: 35px;
  }
  .buyer_seller_cont_outer {
    margin-bottom: 30px;
  }
  .service_provider_cont_box {
    padding: 20px;
  }
  .service_provider_cont_box .info_outer {
    flex-direction: column;
  }
  .service_provider_cont_box .info {
    flex-direction: column;
  }
  .service_provider_cont_box img {
    max-width: 80px;
    max-height: 80px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .service_provider_cont_box .price {
    margin-top: 20px;
    font-size: 20px;
  }
  .service_provider_cont
    .col-md-6:last-child
    .service_provider_cont_box:last-child {
    margin: 0;
  }
  .service_provider_cont_box .info .name {
    font-size: 18px;
  }
  .service_provider_cont_box .pay_hire .price {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .product_details_slider_outer {
    flex-direction: column-reverse;
  }
  .product_details_slider_outer .slider_thumbnails {
    flex-direction: revert;
    padding-right: 0;
    padding-top: 15px;
  }
  .product_details_slider_outer .slider_thumbnails .thumb {
    margin-bottom: 0;
    margin-right: 15px;
  }
  .product_details_cont .breadcrumb {
    margin-bottom: 30px;
  }
  .product_details_cont .product_meta_info {
    margin-top: 50px;
  }
  .product_details_cont .product_meta_info .name {
    font-size: 22px;
  }
  .product_details_cont .prod_des h4 {
    font-size: 22px;
    margin-bottom: 25px;
  }
  .product_details_cont .prod_des {
    margin-top: 50px;
  }
  .related_products {
    margin: 50px auto;
  }
  .timeline:before {
    left: 20px;
    margin-left: 0;
    z-index: 0;
  }
  .timeline li {
    padding-bottom: 50px;
  }
  .flag-wrapper {
    position: relative;
    max-width: 340px;
    padding-left: 75px;
    text-align: left;
  }
  .timeline .hexa {
    left: 23px;
    margin-left: 0;
    top: 0;
  }
  .direction-l img {
    position: relative;
    right: 0;
    display: block;
    margin-top: 40px;
    margin-bottom: 0;
    max-width: 120px;
  }
  .direction-r img {
    position: relative;
    left: 0;
    display: block;
    margin-top: 40px;
    margin-bottom: 0;
    max-width: 120px;
  }
  .flag-wrapper h4 {
    font-size: 20px;
  }
  .how_it_works_cont {
    padding-bottom: 50px;
  }
  .faqs_cont {
    margin: 50px auto;
  }
  .faqs_cont .faqs_collapse {
    padding: 20px;
  }
  .faqs_cont .faqs_collapse .faqs_collapse_header {
    font-size: 16px;
  }
  .faqs_cont .faqs_collapse .faqs_collapse_item {
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .contact_cont .contact_info {
    margin-bottom: 50px;
  }
  .contact_cont .contact_info p {
    margin-bottom: 50px;
  }
  .contact_cont .contact_form {
    padding: 25px;
  }
  .contact_cont .contact_form .form-group label {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .contact_cont .contact_form .form-group .form-control {
    height: 50px;
    padding: 15px;
    font-size: 16px;
  }
  .map {
    margin: 50px auto;
  }
  .login_signup {
    flex-direction: column;
  }
  .login_signup:before {
    display: none;
  }
  .login_signup .left {
    display: none;
  }
  .login_signup .right {
    width: 100%;
    margin: auto;
    padding: 0 20px;
    text-align: center;
    margin-top: 50px;
  }
  .login_signup .right .heading {
    margin-top: 20px;
  }
  .login_signup .right p br {
    display: none;
  }
  .login_signup .right .form-group label {
    display: block;
    text-align: left;
  }
  .other_sec {
    margin-bottom: 50px;
  }
  .other_sec h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .other_sec p {
    margin-bottom: 35px;
  }
  footer h4 {
    margin-bottom: 10px;
  }
  footer ul {
    margin-bottom: 15px;
  }
  footer ul li {
    display: inline-block;
  }
  footer ul li a {
    padding: 0;
    display: block;
  }
  footer ul.contact_ul {
    margin: 0;
  }
  footer ul li {
    font-size: 15px;
    display: block;
    padding: 12px 0 !important;
  }
  .footer_bottom p {
    text-align: center;
    margin-bottom: 15px;
  }
  .footer_bottom .payment_icons {
    justify-content: center;
    padding-bottom: 5px;
  }
  .footer_bottom .payment_icons img {
    margin-left: 6px;
    margin-right: 6px;
  }
  .sd_add {
    margin-top: 20px;
  }
  .topbar .row.row {
    text-align: center;
  }
  .topbar .right_side {
    justify-content: center;
    margin-top: 5px;
  }
  .topbar {
    font-size: 12px;
  }
  .right_side a {
    display: flex;
    align-items: center;
  }
  .right_side select {
    width: 130px;
    padding: 5px 10px !important;
    font-size: 13px;
    height: auto !important;
  }
  header .header_box form .form-control {
    font-size: 14px;
    padding: 0 50px 0 15px;
  }
  header p {
    margin: 25px auto;
  }
  .buyer_seller .col-md-4:first-child {
    margin-bottom: 30px;
  }
  .buyer_seller img {
    display: block;
    margin: 0 auto 25px;
  }
  .crop_process .col-md-3 {
    width: 50%;
    flex: inherit;
  }
  .crop_process .crop_process_box h5 {
    margin-left: 0;
    font-size: 15px;
  }
  .service_provider_box {
    margin-bottom: 50px;
  }
  .home_buy_seeds .home_buy_seeds_carousel .owl-nav button.owl-next {
    margin-right: -8px;
  }
  .home_buy_seeds .buy_seeds_box .img_outer img {
    height: 170px;
  }
  .home_buy_seeds .home_buy_seeds_carousel .owl-nav button {
    height: 28px;
    width: 28px;
    font-size: 1.1rem !important;
  }
  .home_buy_seeds .right .owl-stage {
    display: flex;
  }
  .home_buy_seeds .buy_seeds_box {
    height: 100%;
  }
  .home_buy_seeds .buy_seeds_box .average,
  .home_buy_seeds .buy_seeds_box .price,
  .home_buy_seeds .buy_seeds_box .name {
    font-size: 15px;
  }
  .newsletter form {
    margin-top: 50px;
    padding: 10px 0;
  }
  footer {
    padding: 50px 0;
  }
  .service_provider .btn.cstm_btn {
    padding: 10px 25px;
  }
  .facts .col-lg-12 {
    padding: 0;
  }
  .newsletter form .submit_btn {
    font-size: 13px;
    padding: 10px 15px;
  }
  ul.timeline {
    width: 100%;
  }
  .timeline .hexa {
    display: none;
  }
  .direction-r,
  .direction-l {
    width: 100%;
    text-align: left;
  }
  .flag-wrapper {
    max-width: 100% !important;
    padding-left: 50px !important;
    width: 100%;
    padding-right: 0 !important;
  }
  .faqs_cont details summary {
    font-size: 18px;
    padding: 20px 22px;
  }
  .flag-wrapper h6 {
    position: absolute;
    left: 6px;
  }
  .direction-r img,
  .direction-l img {
    margin-top: 0;
    max-width: 100px;
  }
  .faqs_cont details p {
    font-size: 15px;
    line-height: 24px;
    padding: 5px 25px 30px 25px;
  }
  .map {
    height: 450px;
  }
  .other_sec {
    margin: 50px auto;
  }
  .service_provider_cont .col-md-6 {
    margin-bottom: 20px;
  }
  .service_provider_details_cont p {
    font-size: 14px;
    line-height: 22px;
  }
  .service_provider_cont .position-relative {
    padding: 0;
  }
  .service_provider_details_cont,
  .service_detail .service_provider_cont {
    margin-bottom: 60px;
  }
  .service-relate .col-lg-2 {
    flex: inherit;
    width: 50%;
    margin: 15px 0;
  }
  .service-relate {
    justify-content: center;
  }
  .all_products .content .col-6 {
    margin-bottom: 20px;
    padding: 0px 10px;
  }
  .all_products .content .row {
    margin: 0 -10px;
  }
  .main-product.collection-otr .side_add_cont {
    margin: 0;
  }
  .product-card .img-outer .time {
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 11px;
    top: 8px;
    right: 8px;
  }
  .related_products .col-6 {
    margin: 10px 0;
    padding: 0px 10px;
  }
  .related_products .row {
    margin: 0 -5px;
  }
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right,
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    width: 100%;
  }
  .image-gallery-slide-wrapper.left {
    padding-left: 0;
    margin-bottom: 15px;
  }
  .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
  .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
    position: inherit;
  }
  .image-gallery-thumbnails-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .image-gallery-thumbnails button.image-gallery-thumbnail {
    margin: 0px 2px !important;
  }
  .image-gallery-content {
    display: flex;
    flex-direction: column-reverse;
  }
  .image-gallery-thumbnails-wrapper.left {
    height: auto !important;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0;
  }
  .image-gallery-slide-wrapper.left
    button.image-gallery-icon.image-gallery-fullscreen-button
    svg {
    height: 26px;
    width: 26px;
  }
  .container.position-relative > .row {
    margin-bottom: 50px;
  }
  .login_signup .right .form-group .form-control {
    font-size: 14px;
    padding: 0 15px !important;
  }
  #country_code {
    width: 100%;
  }
  .login_signup {
    height: auto;
    margin-bottom: 50px;
  }
  .tabs.logintabs .tab-content {
    padding-bottom: 0;
  }
  .notification_ul li span.float-right {
    display: block;
    margin: 0 !important;
    padding-top: 5px;
  }
  .notification_grid .ml-auto img.float-right {
    width: 20px !important;
    height: 20px !important;
    margin-left: 20px !important;
  }
  .dashboard-container {
    padding: 100px 20px;
  }
  body .header {
    padding: 6px 10px;
  }
  ul.px-3.ml-auto.c-header-nav {
    padding-right: 0 !important;
  }
  li.c-header-nav-items.nav-item a {
    padding-right: 0 !important;
  }
  .scroll_div {
    margin-left: -20px;
    margin-right: -20px;
  }
  .notification_ul li {
    font-size: 15px;
    padding: 15px 0;
  }
  .dashboard-container .w-50 {
    width: 100% !important;
  }
  body .dash_edit_card .form-control {
    padding: 12px 12px !important;
    font-size: 14px;
  }
  body .dash_edit_card .form-group i.fa-eye-slash {
    margin: 16px 0;
    font-size: 12px;
  }
  .c-header-nav .form-group.focused.m-0 {
    margin-right: 10px !important;
  }
  li.c-header-nav-items.mx-2.dropdown.nav-item {
    margin: 0 !important;
  }
  .profile-page {
    padding: 10px 20px;
  }
  .img-wrap {
    width: 170px;
    height: 170px;
  }
  body .dash_edit_card label {
    font-size: 15px;
  }
  .card.card-profile.shadow {
    padding-top: 100px;
  }
  .steps_card_info .card_box {
    margin-left: auto;
    margin-right: auto;
  }
  .steps_card_info.w-100 {
    padding-bottom: 0;
  }
  .steps_card_info > div {
    padding-right: 0;
  }
  .cs-conversation__name {
    font-size: 1.3em !important;
  }
  .cs-conversation > .cs-avatar {
    width: 50px !important;
    height: 50px !important;
    margin-right: 1em;
  }
  .cs-chat-container .cs-message-list {
    height: 100%!important;
}
.messages_div {
  padding: 0px 0px 100px !important;
}
.dashboard_outer{
  height: 100vh !important;
  overflow: hidden !important;
}
.messages_div::-webkit-full-page-media, _:future, :root .safari_only {
  padding-bottom: 65px;  
  
}

}
@media (max-width: 575px) {
  .input-container{
    left: 5px !important;
    bottom:0 !important;
  }
  .service_provider_details_cont .info_outer {
    flex-direction: column;
  }
  .service_provider_details_cont h3 {
    margin-top: 30px;
    margin-bottom: 25px;
  }
  .service_provider_details_cont .info_outer .dets {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    flex-direction: row-reverse;
  }
  .service_provider_details_cont .info_outer .chat_btn {
    margin: 0;
  }
  .service_provider_details_cont ul {
    padding-left: 30px;
    margin-bottom: 25px;
  }
  .container.position-relative .side_add_cont img {
    max-width: 50%;
    margin: 0;
    padding: 5px;
  }
  .dets .btn.cstm_btn {
    padding: 10px 15px;
    min-width: 150px;
  }
  .service_provider_details_cont .info_outer .price {
    font-size: 20px;
  }
  .container.position-relative .side_add_cont {
    flex-wrap: wrap;
  }

  .product-card .img-outer .time {
    font-size: 8px;
  }
  .grid-address {
    max-width: 100%;
  }
  .headingWrap .ml-auto {
    display: flex;
    align-items: center;
  }
  .headingWrap .btn.cstm_btn {
    padding: 8px 20px;
  }
  .dashboard-container .form-group.float-right .ml-4.btn {
    margin-left: 10px !important;
  }
  .dashboard-container .form-group.float-right button {
    padding: 10px 15px;
  }
  #background {
    left: 0;
  }
  .messages_div {
    margin-top: -28px;
  }
  .chat_view {
    display: block;
  }
}
@media (min-width: 1200px) {
  .container,
  .product_details_cont .container {
    max-width: 1170px;
  }
}
@media (min-width: 1441px) and (max-width: 1600px) {
  .side_add_cont {
    right: -160px;
    max-width: 160px;
  }
  .collection-otr .container {
    max-width: 1100px;
  }
}
@media (min-width: 1281px) and (max-width: 1440px) {
  /*	.container {
max-width: 967px;
}*/
  .side_add_cont {
    right: -130px;
    max-width: 130px;
  }
  .collection-otr .container {
    max-width: 1000px;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  nav.navbar {
    padding: 10px 0;
  }
  header h1 {
    font-size: 60px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 25px;
  }
  .crop_process .crop_process_box h5 {
    font-size: 18px;
  }
  .small_heading {
    font-size: 18px;
  }
  .heading {
    font-size: 45px;
    line-height: 50px;
  }
  .buyer_seller h3 {
    font-size: 35px;
  }
  .service_provider_outer .service_provider_box h3 {
    font-size: 22px;
  }
  .facts h4 {
    font-size: 35px;
  }
  .facts .facts_box {
    font-size: 55px;
    line-height: 50px;
  }
  .home_buy_seeds .buy_seeds_box .name,
  .home_buy_seeds .buy_seeds_box .average,
  .home_buy_seeds .buy_seeds_box .price {
    font-size: 16px;
  }
  .testimonial_feeds .testimonials_box .img_outer .author {
    font-size: 16px;
  }
  .testimonial_feeds .feeds_box_outer .feeds_box .content_sec h5 {
    font-size: 18px;
    line-height: 22px;
  }
  .testimonial_feeds .feeds_box_outer .feeds_box .content_sec .date {
    font-size: 14px;
  }
  .newsletter h4 {
    font-size: 30px;
  }
  nav.navbar .navbar-nav .nav-link {
    padding: 11px 25px;
  }
  .mission_vision h4 {
    font-size: 26px;
    margin-bottom: 25px;
  }
  .all_products .filters .filter_box {
    padding: 25px 30px 15px 30px;
  }
  .all_products .filters .filter_box h5 {
    font-size: 18px;
  }
  .w-50 {
    width: 100% !important;
}
  .browse_category_heading {
    font-size: 27px;
  }
  .browse_category_heading img {
    max-width: 40px;
  }
  .browse_category_carousel.owl-carousel .owl-nav button {
    height: 40px;
    width: 40px;
  }
  .browse_category_carousel.owl-carousel .owl-nav {
    top: -70px;
  }
  .browse_category_carousel .browse_category_box .title {
    font-size: 18px;
  }
  .buyer_seller_cont {
    margin-bottom: 80px;
  }
  .buyer_seller_cont h4 {
    font-size: 38px;
    line-height: 44px;
  }
  .buyer_seller_cont h5 {
    font-size: 18px;
  }
  .service_provider_cont_box {
    padding: 25px;
    margin-bottom: 30px;
  }
  .service_provider_cont_box .pay_hire .cstm_btn {
    width: 125px;
  }
  .service_provider_cont_box .info .name {
    font-size: 20px;
  }
  .service_provider_cont_box .info .rating {
    margin: 10px auto;
  }
  .service_provider_cont_box img {
    max-width: 90px;
  }
  .product_details_cont .product_meta_info .name {
    font-size: 28px;
  }
  .product_details_cont .product_meta_info ul li {
    font-size: 16px;
  }
  .product_details_cont .product_meta_info .price {
    font-size: 16px;
  }
  .product_details_cont .prod_des h4 {
    font-size: 28px;
  }
  .flag-wrapper h4 {
    font-size: 24px;
    margin: 15px auto;
  }
  .faqs_cont .faqs_collapse .faqs_collapse_header {
    font-size: 18px;
  }
  .contact_cont .contact_form .form-group label {
    font-size: 16px;
  }
  .contact_cont .contact_form .form-group .btn.cstm_btn {
    font-size: 16px;
  }
  .login_signup .left img {
    max-width: 450px;
  }
  .login_signup .right .heading {
    margin-top: 30px;
  }
  .login_signup .right .form-group {
    margin-bottom: 30px;
  }
  .login_signup .right h6 {
    margin: 20px 0 0 0;
    font-size: 16px;
  }
  .login_signup .right .form-group label {
    font-size: 16px;
  }
  .other_sec h5 {
    font-size: 22px;
    margin-bottom: 25px;
  }
  .other_sec {
    margin-bottom: 100px;
  }
}

@media (max-width: 480px) {
  .product-card .img-outer img.product_img {
    height: 160px;
  }
 
}
@media (max-width: 375px) {
  .product-card .img-outer img.product_img {
    height: 110px;
  }
  .chat_box {
    right: 15px;
  }
  .dashboard-container .form-group.float-right .ml-4.btn {
    margin-left: 8px !important;
  }
  .dashboard-container .form-group.float-right button {
    padding: 10px 12px;
    font-size: 13px;
  }
  .TablePagination span {
    font-size: 15px;
  }
}
@media (max-width: 756px) {
  .d_sm-flex {
    display: flex;
  }
  .back_icon {
    position: absolute;
    top: 15px;
    left:25px;
    width: 0.7em !important;
    height:0.7em !important;
  }
  .cs-conversation-header {
    padding-left: 50px;
  }
  .cs-chat-container {
    width: 100% !important;
    height:calc(100vh - 75px);
  }
  .input-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    left: 20px;
}
/* .chat-attachment {
  position: fixed;
    bottom: 28px;
} */
.cs-message-list {
  padding-top: 55px;
  padding-bottom: 55px;
  height: 90% !important;
}
.cs-conversation-header {
  position: fixed;
  z-index: 1;
  right: 20px;
  left: 20px;
  margin-top: -4px;
}
svg.MuiSvgIcon-root.icon-size.back_icon.d-lg-none.d-md-none.d-sm-block {
  z-index: 2;
  position: fixed;
    top: 85px;
}
.messages_div {
  height: 100%;
}
  /* .dashboard-container{
    padding-top:82px !important;
  } */
  .cs-message-list{
    height: 100% !important;
    /* --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  height: calc(100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));
    height: calc(100vh -env(safe-area-inset-bottom)) !important; */

  }
  .chat_design .top{
    position: fixed;
    z-index: 1000;
    right: 0;
    left: 16px;
    margin-top: -50px;
    background: white;
}
.chat_design .message-container{
  height: calc(100vh - 186px);
}
  #background {
    text-align: center;
    margin: auto;
    width: 100%;
  }
  .thankyou_img{
    width: 100%;
    height: 100%;
    display: flex;
    margin: auto;
  }
  .backout_modal{
    width: 100% !important;
}
input#quantity{
  width: 65%;
}
.address .d-grid {
  grid-template-columns: 1fr;
}
}
@media (max-width: 576px) {
  span.report.ml-auto {
    margin-left: 100px !important;
  }
  body .modal .modal-body {
    padding: 14px;
  }
  .service_grid {
    display: list-item !important;
  }
  .grid-address p {
    margin-top: 0 !important;
  }
  .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item {
    padding-left: 30px;
  }
  .scroll_div {
    height: 100%;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  .container {
    overflow-x: hidden;
  }
 
  .c-wrapper,
  .header_position {
    position: absolute;
  }
  .testTables {
    display: block;
    overflow-x: scroll;
    table-layout: fixed;
    padding-top: 0;
    scrollbar-width: none;
  }
 
  @supports (-moz-appearance: none) {
    .direction-l .flag-wrapper h6 {
      left: -44px;
    }
  }
  .sidebar_naviagtion{
    position: relative !important;
  }
  /* css written by  Tarun */
  #language {
    padding: 4px 8px !important;
    width: 85px !important;
}
.tp-header {
  padding: 15px !important;
}
.navbar {
  padding: 14px 20px !important;
}
.ban-heading {
  font-size: 48px;
}
.cstm_header .searchbar {
  width: 100% !important;
}
.first-hm-section {
  margin: 80px 0;
}
.first-hm-section .single-user {
  margin-top: 24px;
}
.single-user img {
  width: 100%;
}
.web-text {
  margin-top: 10px;
  margin-bottom: 10px;
}
.hw-content {
  padding: 0 !important;
}
.how-work .hw-content h1 {
  font-size: 70px;
}
.how-work {
  margin-bottom: 80px;
}
.about_text .web_heading {
  padding: 40px 0;
}
.service-provider {
  margin: 80px 0;
}
.greenery h1 {
  font-size: 38px;
}
.greenery h6 {
  font-size: 18px;
}
.products {
  margin: 80px 0;
}
.feeds {
  margin: 80px 0;
}
footer form button {
  padding: 6px 20px;
}
.nmm{
  margin-top: 0 !important;
}
.responsive-iframe {
  width: 100%;
  height: auto;
}
.about_text .web-heading, .about_text h2, .about_text p {
  color: #000;
}
.about_text p {
  color: #000;
}
.about_text .web_heading{
  position: unset;
  top: unset;
}
.contact_cont .contact_info {
  margin-bottom: 0;
}
.testimonial-back {
  margin-top: 114px !important;
}


}
@media (max-width: 320px) {
  span.report.ml-auto {
    margin-left: 60px !important;
  }
  body .TablePagination{
    padding: 10px;
  }
}
@media only screen and (max-width:400px){
  .headingWrap .btn.cstm_btn {
    padding: 8px 10px;
  }
}