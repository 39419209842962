.chatarea {
    position:fixed;
    bottom:0px;
    right:30px;
    margin:0px;
    padding:0px;
    z-index:1000;
  }
  #togglearea{
    height: 400px;
      overflow-y: scroll;
   
  }
  .chat {
    margin:0px;
    padding:0px;
    width:250px;
    float:right;

    -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;;
    overflow:hidden;
    /* margin-right:30px; */
    -webkit-box-shadow: 0px 1px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px 1px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         0px 1px 5px 0px rgba(50, 50, 50, 0.75);
  }
  .chathead {
    width:250px;
    background-color:#1a1a1a;
    height: 40px;
    color:#ffffff;
    padding-top:5px;
    padding-left:10px;
    cursor:pointer;
  }
  /* .chathead img {
    width:20px;
    vertical-align:middle;
    padding-right:10px;
  } */
 
  .feed {
    padding:10px;
    background-color:#f2f2f2;
    /* max-height:300px;
    overflow-y:scroll;
    overflow-x:hidden; */
  }
  .feed img {
    width:100%;
    /* border-radius: 50px;
    width: 25px;
    height: 25px; */
  }
  .other, .me {
    position:relative;
    margin-top:10px;
    
  }
  .me {
    -webkit-animation:intro 1s;
    animation:intro 1s;
  }
  .other {
    -webkit-animation:intro2 1s;
    animation:intro2 1s;
    position:relative;
  }
  .other{}
  .other .profile {
    position:absolute;
    top:0px;
    left:0px;
  }
  .other .message {
    min-height:30px;
    margin-left:30px;
    border:1px solid #E0E0E0;
    background-color:#ffffff;
    padding:10px;
    color:#1a1a1a;
  }
  
  .other .message:before {
    content:"";
        width: 0; 
      height: 0;
    position:absolute;
    top:0px;
    left:27px;
      width: 0; 
      height: 0; 
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right:10px solid #ffffff; 
  }
  .me .profile {
    position:absolute;
    bottom:0px;
    right:0px;
  }
  .me .message {
    min-height:30px;
    max-width:161px;
    border:1px solid #E0E0E0;
    background-color:#ffffff;
    padding:10px;
    color:#1a1a1a;
  }
  .meta {
    padding-top:5px;
    font-size:12px;
    color:#808080;
  }
  .messagebox {
    min-height:30px;
    width:250px;
    border-top:1px solid #e0e0e0;
    background-color:#ffffff;
    position: fixed;
    right: 29px;
    bottom: 0;
  }
  .messagebox textarea {
    width:250px;
    box-sizing:border-box;
    height:100%;
    border:0px;
    font-family:Arial;
    padding-left:10px;
  }
  @-webkit-keyframes intro {
    0% { opacity:0; left:25px;}
    100% { opacity:1; left:0px;}
  }
  @-webkit-keyframes intro2 {
    0% { opacity:0; left:-25px;}
    100% { opacity:1; left:0px;}
  }
  @keyframes intro {
    0% { opacity:0; left:25px;}
    100% { opacity:1; left:0px;}
  }
  @keyframes intro2 {
    0% { opacity:0; left:-25px;}
    100% { opacity:1; left:0px;}
  }
  .chat-room-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 16px;
}

.room-name {
    margin-top: 0;
}

.messages-container {
    flex: 1;
    min-height: 100px;
    overflow: auto;
    border: 1px solid black;
    border-radius: 7px 7px 0 0;
}

.messages-list {
    list-style-type: none;
    padding: 0;
}

.new-message-input-field {
    height: 200px;
    max-height: 50%;
    font-size: 20px;
    padding: 8px 12px;
    resize: none;
}

.message-item {
    /* width: 55%; */
    margin: 8px;
    padding: 12px 8px;
    word-break: break-word;
    border-radius: 4px;
    color: white;
}

.my-message {
    background-color: rgb(0, 132, 255);
    margin-left: auto;
}

.received-message {
    background-color: #3f4042;
    margin-right: auto;
}

.send-message-button {
    font-size: 28px;
    font-weight: 600;
    color: white;
    background: #31a24c;
    padding: 24px 12px;
    border: none;
}

.messages-container, .new-message-input-field, .send-message-button {
    border-color: #9a9a9a;
}
.messages-container{
  overflow-y: scroll;
  height:80vh;
}
.cs-message__content{
  margin-top: 1.4em !important;
}
.card_position{
  /* position: absolute; */
    bottom: 0;
}
button.cs-button.cs-button--attachment{
  opacity: 0 !important;
}
.chat-attachment{
  position:absolute;
  bottom: 8px;
}

/*Main Css Start*/
.send_icon{
  width:20px;
  height:20px;
  cursor: pointer;
}
.chat_box {
 
  background: #ffffff;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 15%) 0px 12px 24px 0px;
  overflow: hidden;
  position: fixed;
  bottom: 32px;
  top: initial;
  right: 32px;
  left: initial;
  width: 310px;
  height: 336px;
  z-index: 999;
  transform: scale(1);
  transform-origin: right bottom;
  transition: transform 0.3s ease 0s;

}

.chat_box .chat_header {
  background: #f6f5f3;
  padding: 10px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat_box .chat_header .user_details {
  display: flex;
  align-items: center;
}
.chat_box .chat_header img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.chat_box .chat_header .user_details span {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-left: 10px;
color: #1e2d23;
}
.chat_box .chat_header .user_actions .btns {
  background: transparent;
  border: none;
  padding: 6px;
  color: #d8d4cb;
  outline: none;
  cursor: pointer;
  font-size: 15px;
transition: 0.5s ease;
}
.chat_box .chat_header .user_actions .btns:hover {
color: #1e2d23;
}
.chat_box .chat_body {
  overflow-y: auto;
  height: 220px;
  display: flex;
  flex-direction: column-reverse;
}
.inner_chat_data {
  display: flex;
  padding: 5px 20px 0 20px;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
}
.chat_box .chat_body .message {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 13px;
  align-items: center;
}
.chat_box .chat_body .message p {
  margin: 0;
  line-height: normal;
  background: #fcd804;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 18px;
  border-radius: 30px;
word-break: break-word;
  max-width: 85%;
}
.chat_box .chat_body .message.outgoing_msg img {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  margin-right: 8px;
}
.chat_box .chat_body .message.incoming_msg {
  justify-content: flex-end;
}
.chat_box .chat_body .message.incoming_msg p {
  background: #1e2d23;
}
.chat_box .chat_footer {
  padding: 20px 20px;
}
.chat_box .chat_footer form {
  display: flex;
  align-items: center;
}
.chat_box .chat_footer .cstm_file_uploader {
  position: relative;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background: #d8d4cb;
  margin-right: 12px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
color: #fff;
  font-weight: 100;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
transition: 0.5s ease;
}
.chat_box .chat_footer .cstm_file_uploader:hover {
  background: #1e2d23;
}
.chat_box .chat_footer .cstm_file_uploader input {
  position: absolute;
  left: 0;
  width: 50px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.chat_box .chat_footer form .form-control {
  border: 1px solid #cfd2d7;
  background: #f6f5f3;
  border-radius: 50px;
  width: 100%;
  height: 30px;
  font-size: 14px;
  outline: none;
  padding: 0 15px;
  margin-right: 15px;
  color: #1e2d23;
  font-weight: 500;
  line-height: normal;
}
.chat_box .chat_footer .send_btn {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 0;
  /* background-image: url(images/send_icon.png); */
  background-color: transparent;
  background-size: contain;
  background-position: 50%;
  width: 22px;
  min-width: 22px;
  height: 30px;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  z-index: 100;
  cursor: pointer;
transition: 0.5s ease;
}
.chat_box .chat_footer .send_btn:hover {
  filter: invert(1);
}
/* Scrollbar Styling */
.chat_box .chat_body::-webkit-scrollbar {
  width: 6px;
}
.chat_box .chat_body::-webkit-scrollbar-track {
  background-color: #c3c3c3;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.chat_box .chat_body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #737373; 
}

@media screen and (max-width:414px){
.chat_box {
  max-width: 280px;
}
}
/*Main Css End*/

.Chat_container{
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
/* position: relative; */
height: 100vh;
}









:root {
  --grey-1: #2a2a2b;
  --grey-2: #494848;
  --grey-3: #131312;
  --grey-4: #333334;
  --grey-5: #bbb;
  --color-1: #64af50; /*#1F09AC*/
  --font-1: "Roboto", sans-serif;
}



/* .container {
  display: grid;
  grid-template-columns: 80px auto;
  grid-template-rows: 100vh;
} */

/*======= Side Nav ==========*/

.side-nav {
  background: var(--grey-3);
  color: white;
  font-size: 25px;
  text-align: center;
  height: 100%;
  z-index: 2;
}

.side-nav .profile img {
  margin-top: 15px;
  width: auto;
  height: 60px;
  filter: grayscale(1);
  border-radius: 50%;
}

.icon-container {
  line-height: 60px;
  height: 60px;
}

/* .icon-container:hover {
  background: var(--grey-2);
} */

.icon-container + .active {
  border-left: 8px solid var(--color-1);
}

.top-nav {
  border-bottom: 1px solid grey;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-nav .logo {
  text-align: center;
  font-size: 150%;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: var(--color-1);
}

/* .main-veiw {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 8vh 92vh;
} */



/*=========Message List======*/

.message-list {
  /* background: var(--grey-2); */
  height: 100%;
}

@media screen and (max-width: 640px) {
  .message-list {
    position: absolute;
    display: none;
    z-index: 1;
  }
}

.message-list .list-top {
  display: grid;
  grid-template-columns: 60% 22% 18%;
  padding: 5px;
}

.message-list .list-top .close-list {
  display: none;
}

@media screen and (max-width: 640px) {
  .message-list .list-top {
    grid-template-columns: 60% 20% 10% 10%;
  }
  .message-list .list-top .close-list {
    display: block;
    position: absolute;
    background: transparent;
    border: none;
    content: "\2039";
    font-size: 20px;
    font-family: var(--font-1);
    color: white;
    top: 15px;
    right: 15px;
  }
}

.message-list input {
  display: inline;
  font-size: 20px;
  padding: 10px 10px;
  border-radius: 30px 0px 0px 30px;
  outline: none;
  border: none;
  background: var(--grey-1);
  color: white;
}

.message-list .search {
  display: inline;
  border: none;
  border-radius: 0px 30px 30px 0px;
  background: var(--grey-1);
  color: white;
  outline: none;
}

.message-list .add {
  border: none;
  background: transparent;
  font-size: 30px;
  color: white;
  outline: none;
}

.message-list .contact {
  display: flex;
  height: 50px;
  padding: 10px;
  /* background: var(--grey-1); */
  color: white;
  font-family: var(--font-1);
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .message-list .contact {
    display: grid;
    grid-template-columns: 60px auto;
    grid-template-columns: 0fr 1fr 1fr;
  }
}

.message-list .active {
  background: var(--grey-4);
}

.message-list .unread:before {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: 11px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: var(--color-1);
  z-index: 1;
}

.message-list .name {
  margin: 5px 10px;
  font-size: 16px;
  font-weight: 100;
}

.message-list img {
  height: 100%;
  width: auto;
  max-height: 50px;
  border-radius: 50%;
  filter: grayscale(1);
  z-index: 2;
}

/*======== Chat Part ===========*/

.chat_design {
  display: grid;
  grid-template-rows: 8vh 72vh 5vh;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

/*========= Top Nav ============*/

.chat_design .top {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.chat_design .top .open-message {
  padding: 0 10px;
  color: var(--grey-3);
  display: none;
}

@media screen and (max-width: 640px) {
  .chat_design .top .open-message {
    display: block;
  }
}

.chat_design .top .open-message:hover {
  color: var(--color-1);
  transition: color ease 0.5s;
}

.chat_design .top .name {
  font-size: 20px;
  font-weight: 600;
  color: grey;
  margin-left: 10px;
  width: 200px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.chat_design .top .name img{
  width: 50px;
  height: 50px;
}
.chat_design .top .name  p{
  margin:auto 20px ;
  align-items: center;
}

.chat_design .top .name:hover {
  color: var(--color-1);
  transition: color ease 0.5s;
}

.chat_design .button-container {
  text-align: right;
  width: 75%;
}

.chat_design .button-container button {
  border: none;
  background: white;
  font-size: 20px;
  /* color: var(--grey-2); */
}

.chat_design .button-container button:hover {
  color: var(--color-1);
  transition: color ease 0.5s;
}

/*======== Chat Message Container =====*/

.chat_design .message-container {
  padding: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  width: 100%;
  height: 72vh;
  padding-right: 18px;
  box-sizing: content-box;
}

.chat_design .divider {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: var(--grey-5);
  font-family: var(--font-1);
  text-align: center;
  position: relative;
  display: inline-block;
}

.chat_design .divider span {
  background: white;
  padding: 0px 10px;
}

.chat_design .divider:before {
  content: "";
  position: absolute;
  left: 0;
  top: 14px;
  height: 1px;
  width: 100%;
  background: var(--grey-5);
  z-index: -1;
}

.chat_design .comment {
  min-height: 50px;
  width: 100%;
  float: left;
  margin: 5px 0px;
  text-align: left;
}

.chat_design .me {
  float: right;
  text-align: right;
}

.chat_design .comment img {
  filter: grayscale(1);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  float: left;
}

.chat_design .me img {
  float: right;
}

.chat_design .bubble {
  display: inline-block;
  background: var(--color-1);
  padding: 8px 12px;
  border-radius: 8px;
  color: white;
  font-family: var(--font-1);
  max-width: 55%;
  position: relative;
  margin: 0px 15px;
}

.chat_design .me .bubble {
  background: var(--grey-5);
  text-align: left;
}

.chat_design .other .bubble:before {
  content: "";
  top: 0px;
  left: -25px;
  position: absolute;
  background: transparent;
  height: 0px;
  width: 0px;
  border-left: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 20px solid var(--color-1);
}

.chat_design .me .bubble:after {
  content: "";
  position: absolute;
  top: 0px;
  right: -25px;
  background: transparent;
  height: 0px;
  width: 0px;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 20px solid var(--grey-5);
}

.chat_design .bubble img {
  border-radius: 5px;
  height: 200px;
  width: 100%;
  filter: none;
}

.chat_design .bubble a {
  color: white;
}

/*======== Typing part =========*/

.chat_design .input-container {
  display: flex;
  padding: 10px 6px;
  border-top: 1px solid #ccc;
}

.chat_design .attach {
  border: none;
  border-radius: 50%;
  width: 100%;
  height: 8vh;
  max-height: 60px;
  max-width: 60px;
}

.chat_design .textarea-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.chat_design textarea {
  border: none;
  outline: none;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  resize: none;
  overflow-y: scroll;
  width: 100%;
  height: 8vh;
  padding-right: 18px;
  box-sizing: content-box;
  margin-left: 10px;
}

.chat_design .send {
  border: none;
  background: var(--color-1);
  color: white;
  font-size: 20px;
  height: 8vh;
  width: 8vh;
  border-radius: 50%;
  max-height: 60px;
  max-width: 60px;
}
/*======= Contact List =======*/

.contact-detail {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background: #eee;
  display: none;
  font-family: "Roboto", sans-serif;
  padding: 0px 10px;
  overflow-y: scroll;
}

.close {
  font-size: 20px;
  border: none;
  background: transparent;
}

.contact-detail img {
  width: 70%;
  height: auto;
  display: block;
  margin: 0 auto;
  filter: grayscale(0);
  border-radius: 50%;
}

.contact-detail h3 {
  color: var(--grey-2);
  text-align: center;
  margin: 8px 0px;
  font-size: 22px;
}

.contact-detail .bio {
  text-align: center;
}

.contact-detail ul {
  margin: 0px;
  margin-top: 10px;
  padding: 0px;
}

.contact-detail ul li {
  list-style: none;
  width: 100%;
  background: white;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-radius: 8px;
  margin-bottom: 5px;
}

.contact-detail ul li .contact-img {
  border-radius: none;
  height: auto;
  width: 55px;
  margin: 0 10px;
  display: inline-block;
  filter: grayscale(1);
}

.contact-list ul li .name {
  display: inline-block;
}
.chat_content{
  display: none;
}


.message_input{
  padding: 0 30px !important;
}
.chat_design .me:last-child{
  margin-bottom:30px;
}
.unread_count{
    z-index: 1;
    color: #ffffff;
    font-weight: 700;
    float: right;
    margin-left: auto;
    background: #005b36;
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 15px;
}