@media only screen and (max-width:756px) {
  html:not([dir=rtl]) .c-sidebar:not(.c-sidebar-right) {
    margin-left: 0 !important;
  }
}

.sidebar_naviagtion {
  width: 18.7rem;
  scrollbar-width: none;
  max-width: 18.7rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  background-color: #ffffff;
  /* border-radius: 8px; */
  transition: 167ms ease;
  position: fixed;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1), 0 2px 4px 0px rgba(0, 0, 0, 0.2),
    0 4px 8px 0px rgba(0, 0, 0, 0.2), 0 8px 16px 0px rgba(0, 0, 0, 0.067),
    0 16px 32px 0px rgba(0, 0, 0, 0.133), 0 32px 48px 0px rgba(0, 0, 0, 0.2);
}
.sidebar_naviagtion .active {
  max-width: 3rem;
}

.sidebar_naviagtion .nav_icon {
  display: flex;
  width: max-content;
  height: 3rem;
  align-items: center;
  margin-inline: min(0.75rem, 50%);
  transform: rotatez(270deg);
  justify-content: center;
  transition: all 200ms linear;
}

.sidebar_naviagtion .nav_icon i {
  display: inline-flex;
  width: 24px;
  justify-content: center;
  font-size: 24px;
  margin-inline: min(0.75rem, 50%);
  cursor: pointer;
  color: #6f70b8;
}

.sidebar_naviagtion .nav_icon i:hover {
  color: rgba(123, 166, 231, 0.733);
  transform: scale(1.15);
}
.sidebar_naviagtion .active .nav_icon {
  transform: rotatez(0deg);
}

.sidebar_naviagtion .nav_list {
  flex: 1;
  list-style: none;
}
.sidebar_naviagtion .list-item {
  height: 48px;
  display: flex;
  transition: background 166ms ease;
  position: relative;
}
.sidebar_naviagtion .list-item:nth-of-type(8) {
  margin-top: 3rem;
}
.sidebar_naviagtion .list-item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% - 12px);
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 166ms ease;
  background: linear-gradient(
    to right,
    rgba(123, 166, 231, 0.733) 3px,
    3px,
    #0000
  );
  opacity: 0;
}

.sidebar_naviagtion .list-item:hover {
  background: rgba(219, 220, 228, 0.6);
}

.sidebar_naviagtion .list-item:hover::after {
  opacity: 1;
}

.sidebar_naviagtion .active [data-tooltip]::before {
  content: attr(data-tooltip);
  position: absolute;
  top: 0.25rem;
  display: inline-flex;
  left: 4.5rem;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  background-color: #ffffff40;
  box-shadow: 0 10px 18px -7px #0002;
  opacity: 0;
  transform-origin: left;
  pointer-events: none;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--gray600);
}

.sidebar_naviagtion .active [data-tooltip]:hover::before {
  animation: showTooltip 200ms linear 100ms forwards;
}

@keyframes showTooltip {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.sidebar_naviagtion .list-item i {
  padding: 0.25rem;
  margin-left: 8px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  font-size: 24px;
  color: rgba(42, 114, 207, 0.842);
}

.sidebar_naviagtion a {
  overflow: hidden;
  gap: 16px;
  text-decoration: none;
  align-items: center;
  display: flex;
  color: #333;

}
 a:hover{
  text-decoration: none !important;
}

.sidebar_naviagtion .list-item:hover a {
  font-weight: 700;
}
.sidebar_band{
  background-color: #fff!important;
    padding: 20px;
    box-shadow: -3px 3px 7px 1px rgb(68 102 242/10%)!important;
    text-align: center;
}
ul .sidebar_band.h-100.ps {
  list-style: none;
  margin: 0;
  padding: 0;
  background: hsla(0,0%,80%,.1411764705882353);
}