
.header {
  background-color: #ffffff;
  padding: 12px 10px;
  position: fixed;
  top: 0;
  z-index: 10;
  -webkit-box-shadow: 0 0 10px 1px rgb(68 102 242 / 5%);
  box-shadow: 0 0 10px 1px rgb(68 102 242 / 5%);
  width: calc(100% - 300px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.nav-menus .notification-badge {
  position: relative;
  right: 10px;
  top: -9px;
  padding: 4px 7px;
}
.badge-primary {
  background-color: #fcd804;
}

.img-50 {
  width: 50px;
  height: 50px;
}
.badge-pill {
  border-radius: 10rem !important;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.c-avatar-img {
  width: 100% !important;
  border-radius: 50px !important;
  height:100% !important;
}
.c-header-nav .c-header-nav-link .badge,
.c-header-nav .c-header-nav-btn .badge {
  position: absolute;
  top: 36%;
  margin-top: -16px;

  padding: 4px 7px;
}
.notification .dropdown-menu{
  /* padding:15px !important; */
  min-width:18rem !important;
}
.menu-item{
  font-size: 1rem;
    align-items: center;
    display: flex;
    font-family: 'Cabin', sans-serif !important;
}
.noti-body{
  padding:0px 18px;
  padding-bottom: 10px !important;
  border-bottom: 1px solid lightgray;
  margin: 0;
}
.top-text-block{
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: inherit !important;
  border-bottom:1px solid #f4f4f4 !important;
  position:relative;
}
  .top-text-block:hover:before {
        content: '';
        width: 4px;
        background: #f05a1a;
        left: 0;
        top: 0;
        bottom: 0;
        position: absolute;
    }
  
    .top-text-block:last-child {
      border: none !important;
    }
    .see-all{
      color:#fcd804;
      font-size: 14px;
      font-weight: 800;
      cursor: pointer;
    }
    .see-all:hover{
      text-decoration: underline;
    }
  
   
