@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-underline-position: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #fcd804 !important;
}

.tp-header {
  background-color: #2d5605;
}

.tp-header span {
  color: #fff;
}

.web-title {
  font-size: 20px;
  color: #2d5605;
  font-weight: 500;
}

.web-heading {
  font-size: 48px;
  font-weight: 600;
  color: #000;
}

.web-text {
  font-size: 16px;
  -webkit-columns: #000;
          columns: #000;
}

.web-btn {
  border: none;
  border-radius: 10px;
  padding: 14px 22px;
  color: #fff;
  background-color: #fcd804;
}

/* homepage css */
.hm-banner {
  z-index: -99;
  min-height: 86vh;
  background-image: url("./assets/images//banner3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-clip-path: polygon(100% 0, 100% 91%, 66% 100%, 0 89%, 0 0);
          clip-path: polygon(100% 0, 100% 91%, 66% 100%, 0 89%, 0 0);
}

.ban-heading {
  font-size: 72px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.ban-heading span {
  color: #fcd804;
  border-bottom: 10px solid #fcd804;
}

.searchbar {
  margin-top: 80px;
}

.searchbar input[type="text"] {
  width: 40%;
  padding: 16px;
  border-radius: 50px !important;
  border: none;
}

.searchbar button {
  padding: 16px 20px;
  border-radius: 54px;
  margin-left: -55px;
  border: none;
  color: #fff;
  background-color: #2d5605;
}

.first-hm-section {
  margin: 120px 0;
}

.first-hm-section .single-user {
  margin-top: 80px;
}

.first-hm-section .single-user img {
  border-radius: 10px;
}

.first-hm-section .single-user h6 {
  font-size: 36px;
  font-weight: 600;
  color: #2d5605;
}

.how-work {
  margin-bottom: 120px;
}

.how-work .hw-content h1 {
  color: #2d5605;
  font-size: 102px;
  opacity: .2;
  font-weight: 700;
}

.how-work .hw-content b {
  font-size: 18px;
  font-weight: 700;
}

.how-work .hw-icon {
  width: 207px;
  height: 190px;
  border-radius: 18px;
  border: 1px solid #2d5605;
  background-color: #f6ffed;
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
}

.how-work .hw-icon img {
  width: 78px;
}

.how-work .hw-icon:hover {
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
  border: 1px solid #e8ffd2;
  background-color: #e8ffd2;
}

.tracktor-ban-sec {
  min-height: 1375px;
  /* background-image: url(./assets/images/tracktor.png); */
  background-size: cover;
  background-position: center;
  color: #fff;
  margin-top: -75%;
}
#myVideo {
  width: 100%;
}

.tracktor-ban-sec img {
  width: 146px;
  cursor: pointer;
}

.tracktor-ban-sec h2 {
  color: #fff;
}

.service-provider {
  margin: 120px 0;
}

.service-provider .provider .provider-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #e0e6da;
}

.service-provider .provider .provider-img img {
  width: 62px;
}

.service-provider .provider .provider-text p {
  font-size: 22px;
  font-weight: 600;
}

.greenery {
  min-height: 780px;
  background-image: url(./assets/images/greenery.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}

.greenery span {
  color: #fff;
}

.greenery h1 {
  font-size: 68px;
  font-weight: 600;
  color: #fff;
}

.greenery h2 {
  color: #fff;
}

.greenery h6 {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
}

.products {
  margin: 120px 0;
}

.single-product {
  border-radius: 10px;
  border: 2px solid transparent;
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
}

.single-product:hover {
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
  border: 2px solid #2d5605;
}

.single-product img {
  width: 100%;
  border-radius: 10px;
}

.single-product .auth-detail span {
  font-size: 15px;
  color: #a9a9a9;
  font-weight: 200;
}

.single-product .auth-detail p {
  margin-bottom: 0;
  font-size: 18px;
  color: #2d5605;
  font-weight: 400;
}

.single-product h6 {
  font-size: 28px;
  font-weight: 500;
}

.single-product .product-detail {
  color: #a9a9a9;
  font-size: 15px;
  font-weight: 400;
}

.single-product .product-price-bar h5 {
  font-size: 24px;
  font-weight: 400;
  color: #fcd804;
  margin-bottom: 0;
  font-weight: 500;
}

.single-product .product-price-bar button {
  background-color: #2d5605;
  color: #fff;
  font-size: 15px;
  border-radius: 10px;
  padding: 8px 12px;
  border: none;
}

.plone {
  width: 88px;
  position: absolute;
  top: 14%;
  left: 13%;
}

.plTwo {
  width: 120px;
  position: absolute;
  top: 44%;
  left: 3%;
}

.plThree {
  width: 100px;
  position: absolute;
  left: 14%;
  bottom: 14%;
}

.plFour {
  position: absolute;
  width: 80px;
  top: 12%;
  right: 14%;
}

.plFive {
  position: absolute;
  width: 112px;
  top: 34%;
  right: 9%;
}

.plSix {
  position: absolute;
  width: 94px;
  bottom: 21%;
  right: 15%;
}

.testimonial-back {
  position: relative;
}

.testimonial-back .testi {
  background-color: #fffdeb;
  padding: 20px;
  border-radius: 20px;
}

.testimonial-back .testi .single-testi img {
  width: 74px;
}

.testimonial-back p {
  margin-bottom: 0;
}

.testi-tp-col {
  position: absolute;
}

.feeds {
  margin: 120px 0;
}

.feeds .single-feed h6 {
  padding-left: 12px;
  font-size: 26px;
  font-weight: 600;
  color: #000;
  border-left: 6px solid #2d5605;
}

.feeds .single-feed .text {
  font-size: 18px;
  height: 180px;
}

.feeds .single-feed .feeder-btn {
  padding: 8px 10px;
  border-radius: 10px;
  background-color: #fffdeb;
  -webkit-box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
}

.feeds .single-feed .feeder-btn p {
  margin-bottom: 0;
  font-size: 18px;
  color: #2d5605;
  font-weight: 600;
}

.feeds .single-feed .feeder-btn .feed-date {
  color: #a4a4a4;
  font-size: 16px;
}

.feeds .single-feed .feeder-btn span {
  font-size: 16px;
  color: #a4a4a4;
}

footer {
  padding: 80px 120px;
  background-color: #313b29;
}

footer h6 {
  font-size: 27px;
  color: #fcd804;
  font-weight: 500;
}

footer .text {
  color: #fff;
}

footer li {
  margin: 10px 0;
  font-size: 18px;
  color: #fff;
  list-style-type: none;
}

footer li a {
  font-size: 18px;
  color: #fff;
  list-style-type: none;
  text-decoration: none;
}

footer li a:hover {
  text-decoration: none;
  color: #fff;
  text-decoration: none;
}

footer form input {
  width: 70%;
  padding: 10px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
}

footer form button {
  background-color: #2d5605;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
}

/* .owl-nav {
  display: block !important;
} */

.owl-next {
  width: 50px;
  height: 50px;
}

.owl-next span {
  display: none;
}

.owl-prev span {
  display: none;
}

.owl-prev {
  width: 50px;
  height: 50px;
}

.owl-prev:hover {
  background-color: transparent !important;
}

.owl-next {
  width: 50px;
  height: 50px;
}

.owl-next:hover {
  background-color: transparent !important;
}

.owl-prev {
  background-image: url(./assets/images/prev.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 35px !important;
}

.owl-next {
  background-image: url(./assets/images/next.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 35px !important;
}
/*# sourceMappingURL=style.css.map */
.cstm_header .searchbar{
  width: 40% !important;
  margin-top: 0 !important;  
}
.cstm_header .searchbar input[type="text"]{
  width: 100% !important;
}