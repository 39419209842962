.topbar .right_side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:30px;
}
.topbar {
    background: #f4f4f4;
    padding: 10px 0;
    font-size: 14px;
    color: #1e2d23;
    font-weight: 400;
    text-transform: uppercase;
}

.topbar a.cart_a {
    margin-left: 25px;
    position: relative;
}

.topbar a {
    color: inherit;
    line-height: normal;
    text-decoration: none;
}
.topbar a:hover {
    text-decoration: none;
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.right_side a img{
    height: 30px !important;
    width: 30px;
    border-radius: 50%;
   
align-content: center;
vertical-align: middle !important;
margin: auto;
}
.right_side a{
    display: flex;
    align-items: center;
}
.right_side .user_name{
    font-size: 20px;
    padding: 0 15px;
}
.badge-primary {
    color: #fff;
    background-color: #1e2d23 !important;
}
.topbar a img {
    height: 20px;
    vertical-align: baseline;
}
.notification_icon .badge, .chat_icon .badge{
    position: absolute;
    top:0;
    right: 47%;
}
.navbar {
    background: #fff;
    padding: 22px 0;
    position: sticky;
    top: 0;
    /* z-index: 100; */
    box-shadow: 0px 2px 0px 0px #f0f0f0;
}

.nav-link:hover {
    color: #fcd804 !important;
}
nav.navbar .navbar-nav .nav-link {
    padding: 11px 25px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
.nav_btn_outer {
    display: inline-flex;
}
li.nav-item.nav_btn {
    margin-left: 12px;
}
a.nav-link.nav-button {
    background: #fcd804;
    color: #fff !important;
    border-radius: 5px;
    padding: 11px 17px;
}
.cstm_header.products_header {
    background-image: url('../../assets/images/products_header.jpg');
}
.topbar a.cart_a span {
    background: #fcd804;
    color: #fff;
    height: 13px;
    width: 13px;
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 700;
}
a.nav-link.nav-button:hover {
    background: #005b36;
}
