@import "~react-image-gallery/styles/css/image-gallery.css";

body .btn:focus,
body .btn.focus {
  box-shadow: none !important;
  box-shadow: none !important;
}
body {
  font-family: "Cabin", sans-serif !important;
  overflow-x: hidden;
  background-color: #fff !important;
  scrollbar-width: none;
}
/* .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
} */
header.home_header_bg {
  /* background-image: url("../../../assets/images/orchard.jpg"); */
  /* background-image: url("../../../assets/images/farmer_tamatoes.jpg"); */
  background-image: url("../../../assets/images/farmer_apple.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
header.home_header_bg:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 30%);
  background: linear-gradient(to bottom, #ffffff00, #00000057);
}
header .header_box {
  margin: 0 auto;
  text-align: center;
}
header .pure-header {
  z-index: 1;
  position: relative;
}
header .header_box form {
  position: relative;
  margin-bottom: 50px;
  padding: 0 50px;
}
.position-relative {
  position: relative !important;
}
header .header_box form .search_btn {
  background-image: url(../../../assets/images/search_icon.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 45px;
  height: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
}
header .header_box form .form-control {
  /* box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 20%) !important; */
  border: 1px solid #f3f3f3;
  height: 50px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
  color: #1e2d23;
  padding: 0 60px 0 25px;
}
/* header .header_box form .form-control:focus {
  box-shadow: none;
} */
/* header {
  position: relative;
  padding-top: 90px;
  padding-bottom: 100px;
} */
.crop_process {
  background: #f4f4f4;
  padding: 70px 0;
}
header h1 {
  position: relative;
  z-index: 1;
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  margin: 0;
  line-height: 62px;
  text-transform: capitalize;
  font-family: "Cabin", sans-serif;
}
p {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  color: #1e2d23;
  /* margin-bottom: 45px; */
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.crop_process .crop_process_box {
  display: flex;
  align-items: center;
  width: 100%;
}
header p {
  margin: 40px auto;
  color: #fff;
}
.cstm_header form .form-control {
  box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 20%) !important;
  border: none;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #1e2d23;
  padding: 0 60px 0 22px;
  border-radius: 5px !important;
}
.position-relative {
  position: relative !important;
}
.cstm_header form .search_btn {
  background-image: url("../../../assets/images/search_icon.png");
  /* background-color: transparent; */
  background-size: contain;
  background-position: 10px center;
  border: none;
  width: 50px;
  height: 50px;
  padding: 0;
  position: absolute;
  right: 25px;
  top: 0;
  background-repeat: no-repeat;
  z-index: 100;
}
.about_sec .heading {
  margin: 0;
}
.about_sec p {
  font-size: 16px;
  line-height: 28px;
  margin-top: 35px;
  margin-bottom: 0;
}
/* .mission_vision h4 {
  font-size: 30px;
  font-weight: 700;
  color: #1e2d23;
  margin-bottom: 30px;
  margin-top: 20px;
} */
/* .mission_vision p {
  font-size: 16px;
  line-height: 28px;
  margin: 0;
} */
.cstm_header.about_header {
  background-image: url("../../../assets/images/about_header.jpg");
}
/* .about_sec {
  text-align: center;
} */
.about_sec .img_outer {
  position: relative;
  margin: 50px auto;
}
.about_sec .img_outer img {
  width: 100%;
  border: 3px solid #1e5631;
  border-radius: 0;
}
.mission_vision {
  padding: 120px 0;
  display: flex;
  justify-content: space-between;
}
.mission_vision .mission_vision_box {
  max-width: 330px;
  text-align: center;
  padding: 0 15px;
}
.mission_vision img {
  max-width: 90px;
}
.about_sec .img_outer .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #6e6e6e;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  border: 4px solid #fff;
  box-shadow: 0 0 7px 1px #1e2d237a;
}
.buyer_seller {
  text-align: center;
  padding: 100px 0;
}
.service_provider {
  padding: 100px 0;
  background: #f4f4f4;
}
.service_detail .cstm_header.service_details_header {
  background-image: url("../../../assets/images/service_provider_header.jpg");
}
.service_detail.service_provider_cont {
  margin-bottom: 80px;
}
.service_detail.heading {
  font-size: 45px;
  line-height: 50px;
  font-weight: 700;
  color: #1e2d23;
  margin-bottom: 75px;
}
.service_detail .service_provider_cont {
  margin-bottom: 80px;
}
.service_detail .service_provider_cont_box {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 19%);
  padding: 30px;
  flex-direction: column;
  cursor: pointer;
  height:300px;
}
.service_provider_cont .col-md-6 {
  margin-bottom: 30px;
}
.provider_details i {
  margin-left: 5px;
  font-size: 12px;
}
.service_detail .service_provider_cont_box img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  min-width: 100px;
  min-height: 100px;
  object-fit: cover;
  box-shadow: 0px 0 4px 0px #e9e9e9ab;
}
.service_detail .service_provider_cont_box .info_outer {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 20px;
}
.service_detail .service_provider_cont_box .info {
  display: flex;
}
/* .service_detail .service_provider_cont_box .info .rating span {
    color: #fcd804 !important;
    } */
.verified_tag {
  line-height: normal;
  font-size: 12px;
  color: #aeb1af;
  font-weight: 400;
  border: 1px solid #aeb1af;
  display: inline-block;
  padding: 0px 5px;
  border-radius: 2px;
  margin-bottom: 5px;
}
.service_provider_cont_box .info .name {
  font-size: 22px;
  color: #1e2d23;
  font-weight: 700;
  line-height: normal;
}
.service_detail .service_provider_cont_box .info .rating {
  color: #fcd804;
  /* margin: 11px auto; */
  font-size: 14px;
}
.service_detail .service_provider_cont_box .info .rating span {
  color: #aeb1af;
  font-size: 16px;
  margin-left: 3px;
}
.service_detail .service_provider_cont_box .info .rating span:first-child {
  margin-left: 0;
}
.service_detail .service_provider_cont_box .info .phone_no {
  font-size: 16px;
  font-weight: 400;
  color: #1e2d23;
  line-height: normal;
}
.service_detail .service_provider_cont_box .info .phone_no i {
  color: #fcd804;
  margin-right: 10px;
  font-size: 18px;
}
.service_detail .service_provider_cont_box .provider_details p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
}
.service_detail .service_provider_cont_box .price {
  font-size: 22px;
  font-weight: 700;
  color: #fcd804;
  line-height: normal;
}
.service_provider_details_cont {
  margin-bottom: 80px;
}
.service_provider_details_cont .info_outer {
  display: flex;
  justify-content: space-between;
}
.service_provider_details_cont .info_outer .info {
  display: flex;
  justify-content: space-between;
}
.service_provider_details_cont .info_outer .info img {
  border-radius: 50%;
  max-width: 100px;
  min-width: 100px;
  margin-right: 22px;
  max-height: 100px;
  min-height: 100px;
  object-fit: cover;
  box-shadow: 0px 0 4px 0px #e9e9e9ab;
  margin-left: auto;
}
.service_provider_details_cont .info_outer .info .verified_tag {
  line-height: normal;
  font-size: 12px;
  color: #aeb1af;
  font-weight: 400;
  border: 1px solid #aeb1af;
  display: inline-block;
  padding: 0px 5px;
  border-radius: 2px;
  margin-bottom: 5px;
}
.service_provider_details_cont .info_outer .info .name {
  font-size: 22px;
  color: #1e2d23;
  font-weight: 700;
  line-height: normal;
}
.service_provider_details_cont .info_outer .info .rating {
  color: #fcd804 !important;
  margin: 10px 0 0 0;
  font-size: 14px;
}
.service_provider_details_cont .info_outer .info .rating span {
  /* color: #fcd804 !important; */
  font-size: 20px !important;
  margin-left: 3px;
}
.service_provider_details_cont .info_outer .info .phone_no {
  font-size: 16px;
  font-weight: 400;
  color: #1e2d23;
  line-height: normal;
}
.service_provider_details_cont .info_outer .info .phone_no i {
  color: #fcd804;
  margin-right: 10px;
  font-size: 18px;
}
.service_provider_details_cont h3 {
  position: relative;
  margin-top: 45px;
  margin-bottom: 35px;
  z-index: 1;
  font-size: 18px;
  font-weight: 700;
  color: #1e2d23;
}
.service_provider_details_cont h3:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 10px;
  left: 0;
  background: #dddddd;
  z-index: -1;
}
.service_provider_details_cont h3 span {
  background: #fff;
  padding-right: 25px;
}
.service_provider_details_cont p {
  font-size: 16px;
  line-height: 24px;
}
.service_provider_details_cont ul {
  padding-left: 40px;
  margin-bottom: 35px;
}
.service_provider_details_cont ul li {
  font-size: 16px;
  line-height: 35px;
  color: #1e2d23;
}
.service_provider_details_cont ol {
  padding-left: 15px;
  margin-bottom: 0px;
}
.service_provider_details_cont ol li {
  font-size: 16px;
  line-height: 35px;
  color: #1e2d23;
}
.service_provider_details_cont .info_outer .price {
  font-size: 22px;
  font-weight: 700;
  color: #fcd804;
  line-height: normal;
}
.service_provider_details_cont .info_outer .price sup {
  text-transform: lowercase;
  padding-left: 5px;
}
.service_provider_details_cont .info_outer .button_grid {
  display: flex;
  flex-direction: column;
}
  .btn.chat_btn {
  color: #005b36;
  border-color: #005b36;
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: 0.5s ease-in;
  margin: 15px 0;
}
.service_provider_details_cont .info_outer .chat_btn:hover {
  color: #ffffff;
  background: #005b36;
  border-color: #005b36;
}
.service_provider_details_cont .info_outer .chat_btn i {
  margin-right: 10px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.crop_process .crop_process_box img {
  width: 56px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
img {
  vertical-align: middle;
  border-style: none;
}
.crop_process .crop_process_box h5 {
  font-size: 17px;
  color: #1e2d23;
  font-weight: 700;
  width: 100%;
  margin: 0 0 0 15px;
}
.small_heading {
  color: #fcd804;
  font-size: 20px;
  font-weight: 600;
  /* text-transform: uppercase; */
  margin-bottom: 25px;
}
body {
  background-color: white;
}
.heading {
  font-size: 50px;
  line-height: 56px;
  font-weight: 700;
  color: #1e2d23;
  margin-bottom: 75px;
  /* margin: 50px 0; */
}
.buyer_seller h3 {
  font-size: 40px;
  font-weight: 700;
  color: #1e2d23;
  margin-bottom: 30px;
}
.buyer_seller img {
  border-radius: 50%;
  margin-bottom: 40px;
  width: 100%;
  max-width: 300px;
}
.cstm_header form {
  position: relative;
  max-width: 670px;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
}
.cstm_header {
  height: 230px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 80px;
}
.cstm_header.buyer_header {
  background-image: url("../../../assets/images/buyer_header.jpg");
}
.cstm_header.seller_header {
  background-image: url("../../../assets/images/seller_header.jpg");
}
.cstm_header.privacy_header {
  background-image: url("../../../assets/images/privacy_header.jpg");
}
.cstm_header.terms_header {
  background-image: url("../../../assets/images/terms_header.jpg");
}
.cstm_header.contact_header {
  background-image: url("../../../assets/images/contact_header.jpg");
}
.other_sec h5 {
  font-size: 26px;
  line-height: normal;
  font-weight: 600;
  color: #1e2d23;
  margin-bottom: 20px;
}
.buyer_seller_cont_outer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}
.buyer_seller_cont_outer .left {
  width: 45%;
}
.buyer_seller_cont_outer .right {
  width: 55%;
  padding: 0 60px;
}
.buyer_seller_cont h4 {
  font-size: 40px;
  line-height: 46px;
  font-weight: 700;
  color: #1e2d23;
  margin-bottom: 25px;
}
.buyer_seller_cont h5 {
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
  color: #1e2d23;
  margin-bottom: 35px;
}
.contact_cont .contact_info .heading {
  margin-bottom: 25px;
}
.contact_cont .contact_info p {
  margin-bottom: 60px;
}
.contact_cont .contact_info ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.contact_info ul li a {
  color: inherit;
}
.contact_info ul li a:hover {
  color: #fcd804;
}
.contact_cont .contact_form {
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 19%);
  padding: 55px 40px;
  margin-bottom: 18px;
}
.contact_cont .contact_form .form-group {
  margin-bottom: 25px;
}
.contact_cont .contact_form .form-group:last-child {
  margin: 0;
}
.contact_cont .contact_form .form-group label {
  font-size: 17px;
  line-height: normal;
  font-weight: 600;
  color: #1e2d23;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.contact_cont .contact_form .form-group .form-control {
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  height: 55px;
  padding: 20px;
  font-size: 17px;
  line-height: inherit;
  font-weight: 400;
  color: #1e2d23;
  transition: 0.5s ease;
}
.contact_cont .contact_form .form-group textarea.form-control {
  height: auto;
  padding: 10px 20px;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.btn.cstm_btn {
  background: #fcd804;
  color: #fff;
  border-radius: 5px;
  padding: 10px 40px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: 0.5s ease-in;
  margin: 15px 0;
}
.btn.cstm_btn:hover {
  background: #005b36;
  color: #fff;
  outline: none;
}
.contact_cont .contact_form .form-group .btn.cstm_btn {
  width: 100%;
  height: 54px;
  font-size: 17px;
  font-weight: 600;
}
.cstm_header.product_details_header {
  background-image: url("../../../assets/images/products_header.jpg");
}
.cstm_header.how_it_works_header {
  background-image: url("../../../assets/images/how_it_works_header.jpg");
}
.faqs_cont details {
  box-shadow: 1px 4px 10px 0px rgb(0 0 0 / 14%);
  margin-bottom: 25px !important;
}
.faqs_cont details summary {
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
  color: #1e2d23;
  cursor: pointer;
  position: relative;
  padding: 25px 45px;
  /* list-style: none; */
}
.faqs_cont details summary::marker {
  display: none;
}
.faqs_cont details summary:before {
  /* content: "\f078";
    font-family: "Font Awesome 5 Free"; */
  background: url("https://cdn-icons-png.flaticon.com/512/32/32195.png") !important;
  color: #1e2d23;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 45px;
  top: auto;
  transition: 0.5s ease;
}
.faqs_cont details[open] summary:before {
  /* content: "\f0da" !important; */
  background: url("https://cdn-icons-png.flaticon.com/512/32/32195.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
}
.faqs_cont details p {
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  padding: 5px 45px 50px 45px;
}

.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.flag-wrapper h6 {
  height: 30px;
  width: 30px;
  background: #fcd804;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.flag-wrapper p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #1e2d23;
  margin: 0;
}

.timeline li {
  padding-bottom: 130px;
}

.direction-r {
  position: relative;
  width: 310px;
  float: right;
  text-align: left;
}

.flag-wrapper {
  display: inline-block;
  padding-left: 40px;
}

.timeline .hexa {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  background: #fcd804;
  z-index: 5;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -30px;
  margin-top: 0;
}

.animate {
  opacity: 1;
  transform: translate(0);
}

.direction-r img {
  position: absolute;
  left: -200px;
}

ul.timeline {
  width: 660px;
  margin: 0 auto;
  margin-top: 20px;
}

.direction-l {
  position: relative;
  width: 310px;
  float: left;
  text-align: right;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: " ";
  display: block;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background: #fcd804;
  z-index: 5;
}

.direction-l .flag-wrapper {
  padding-left: 0;
  padding-right: 40px;
}

.flag-wrapper {
  position: relative;
}

.direction-l img {
  position: absolute;
  right: -210px;
}

.timeline li:last-child {
  padding-bottom: 50px;
}

.timeline {
  position: relative;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  list-style-type: none;
  overflow: hidden;
}

.flag-wrapper h6 {
  height: 30px;
  width: 30px;
  background: #fcd804;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.flag-wrapper h4 {
  font-size: 26px;
  line-height: normal;
  font-weight: 600;
  color: #1e2d23;
  margin: 20px auto;
}

.timeline .hexa {
  left: -28px;
  right: auto;
  top: 0px;
}

.faqs_cont {
  margin: 130px auto;
}

.faqs_cont .faqs_collapse {
  box-shadow: 1px 4px 10px 0px rgb(0 0 0 / 14%);
  padding: 25px 45px;
  margin-bottom: 25px;
}

.faqs_cont .faqs_collapse:last-child {
  margin-bottom: 0;
}

.faqs_cont .faqs_collapse .faqs_collapse_header {
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
  color: #1e2d23;
  cursor: pointer;
  position: relative;
}

.faqs_cont .faqs_collapse .faqs_collapse_header:before {
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  color: #1e2d23;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.5s ease;
}

.faqs_cont .faqs_collapse .faqs_collapse_header.active:before {
  content: "\f0da";
}

.faqs_cont .faqs_collapse .faqs_collapse_item {
  display: none;
  padding-top: 30px;
  padding-bottom: 25px;
}
.faqs_cont .faqs_collapse .faqs_collapse_item.show {
  display: block;
}
.faqs_cont .faqs_collapse .faqs_collapse_item p {
  margin: 0;
  font-size: 16px;
  line-height: 28px;
}
.active {
  color: #fcd804;
}
.side_add_cont {
  position: absolute;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  right: -212px;
  max-width: 200px;
}
.side_add_cont img {
  max-width: 160px;
  width: 100%;
  margin-bottom: 16px;
  height: 100%;
}
.report {
  cursor: pointer;
}
.all_products .filters .side_add img,
.side_add img {
  max-width: 100%;
  margin-bottom: 20px;
}
.text-center {
  text-align: center !important;
}

.contact_info ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #1e2d23;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fcd804;
  color: #fcd804;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  font-size: 11px;
  margin-right: 20px;
}

.service_provider_box {
  margin-bottom: 64px;
}

.owl-carousel .owl-item {
  cursor: pointer;
}

.owl-carousel .service_provider_box img {
  display: block;
  height: 81px;
  width: 92px !important;
  margin: auto;
}
.owl-carousel .testimonials_box .img_outer {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: default;
}
.owl-carousel .testimonials_box .img_outer .author {
  font-size: 18px;
  font-weight: 700;
  color: #1e2d23;
  margin-left: 20px;
  cursor: default;
}
.owl-carousel .testimonials_box .img_outer img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 2px solid #fcd804;
  cursor: default;
}
.owl-carousel .testimonials_box p {
  cursor: default;
}
.owl-carousel .feeds_box_outer .feeds_box .img_sec img,
.owl-carouse .feeds_box_outer .feeds_box .content_sec.category,
.owl-carousel .feeds_box_outer .feeds_box .content_sec h5,
.owl-carousel .feeds_box_outer .feeds_box .content_sec .date {
  cursor: default;
}

.heads {
  text-align: center;
}

img.ab_img {
  width: 100%;
  border: 3px solid #1e5631;
  border-radius: 5px;
}
.home_buy_seeds .left {
  width: 35%;
}
.home_buy_seeds .left img {
  width: 100%;
  height: 100%;
}
.home_buy_seeds {
  display: flex;
  flex-direction: row;
  margin: 120px auto;
}
.home_buy_seeds .owl-carousel.home_buy_seeds_carousel .owl-item {
  padding: 10px;
}
.home_buy_seeds .buy_seeds_box {
  padding: 16px;
  position: relative;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 14%);
  height: 380px;
}
.home_buy_seeds .buy_seeds_box .img_outer {
  position: relative;
}
.home_buy_seeds .buy_seeds_box .img_outer img {
  width: 100%;
}
.home_buy_seeds .buy_seeds_box .img_outer .time {
  padding: 2px 10px;
  background: #fcd804;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 10px;
  right: 10px;
  text-transform: uppercase;
}
.home_buy_seeds .buy_seeds_box .name {
  font-size: 17px;
  font-weight: 600;
  color: #1e2d23;
  margin-top: 25px;
}
.home_buy_seeds .buy_seeds_box .average {
  font-size: 17px;
  font-weight: 400;
  color: #1e2d23;
  margin: 5px 0;
}
.home_buy_seeds .buy_seeds_box .price {
  color: #fcd804;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
}
.home_buy_seeds .buy_seeds_box .fav_icon {
  position: absolute;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
  color: #fcd804;
}
.home_buy_seeds .buy_seeds_box .fav_icon:hover {
  color: #1e2d23;
}

.home_buy_seeds .home_buy_seeds_carousel .owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.home_buy_seeds .home_buy_seeds_carousel .owl-nav button {
  height: 35px;
  width: 35px;
  font-size: 1.4rem !important;
  color: #fff !important;
  border: none !important;
  background: #fcd804 !important;
  transition: 0.5s ease;
}

.home_buy_seeds .home_buy_seeds_carousel .owl-nav button:hover {
  color: #ffffff;
  background: #1e2d23;
}
.home_buy_seeds .home_buy_seeds_carousel .owl-nav button.owl-prev {
  margin-left: -8px;
}
.home_buy_seeds .owl-carousel .owl-nav.disabled {
  display: flex;
}
.home_buy_seeds .right {
  border: 2px solid #f1e9e9;
  width: 65%;
  border-left: 0 !important;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.testimonial_feeds {
  background: #f4f4f4;
  padding: 120px 0;
}
.owl-theme .owl-dots .owl-dot span {
  height: 10px;
  width: 10px;
  border: 2px solid #fcd804;
  border-radius: 50%;
  margin-right: 10px;
  transition: 0.5s ease;
  background: transparent !important;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #fcd804 !important;
}
.owl-theme .owl-dots .owl-dot span .owl-dot.active {
  background: #fcd804 !important;
}

.feeds_box_outer .feeds_box:first-child {
  margin-bottom: 20px;
}
.feeds_box_outer .feeds_box {
  display: flex;
}
.feeds_box_outer .feeds_box .img_sec img {
  width: 100%;
  min-width: 140px;
}
.feeds_box_outer .feeds_box .content_sec {
  padding: 0 45px;
}
.feeds_box_outer .feeds_box .content_sec .category {
  font-size: 14px;
  font-weight: 400;
  color: #fcd804;
  text-transform: uppercase;
  line-height: normal;
}
.feeds_box_outer .feeds_box .content_sec h5 {
  font-size: 20px;
  font-weight: 400;
  color: #1e2d23;
  line-height: 26px;
  margin: 10px auto;
}
.feeds_box_outer .feeds_box .content_sec .date {
  font-size: 16px;
  font-weight: 400;
  color: #1e2d23;
  line-height: normal;
}
.feeds_carousel .owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: -130px;
  right: 0;
}
.feeds_carousel .owl-nav button {
  height: 26px !important;
  width: 26px;
  color: #ffffff;
  border: 2px solid #fcd804 !important;
  background: #fcd804 !important;
  border-radius: 50% !important;
  transition: 0.5s ease;
  font-size: 14px;
}
.feeds_carousel .owl-nav button:hover {
  color: #ffffff !important;
  border: 2px solid #1e2d23;
  background: #1e2d23;
}
.feeds_carousel .owl-nav button.owl-prev {
  border: 2px solid #1e2d23;
  background: #ffffff;
  color: #1e2d23;
  margin-right: 10px;
}
.feeds_carousel .owl-nav button.owl-prev:hover {
  color: #fcd804;
  border-color: #fcd804;
}

.newsletter {
  background: #fcd804;
  padding: 70px 0;
  color: #fff;
  position: relative;
}
.newsletter:before {
  content: "";
  background-image: url("../../../assets/images/newsletter_arrow.png");
  background-size: contain;
  background-position: center;
  width: 194px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 37%;
}
.newsletter h4 {
  font-size: 34px !important;
  font-weight: 700;
  margin-bottom: 30px;
  color: #ffffff;
}
.newsletter p {
  color: inherit;
  line-height: 28px;
  font-size: 16px;
  margin: 0;
}
.newsletter form {
  display: flex;
  border-bottom: 2px solid #fff;
  padding: 16px 0;
}
.newsletter form .form-control {
  border: none;
  background: transparent;
  border-radius: 0;
  color: #fff;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}
.newsletter form input[type="email"].form-control::-webkit-input-placeholder {
  color: #fff;
}
.newsletter form .submit_btn {
  font-size: 14px;
  font-weight: 700;
  color: #76ba1b;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 10px 25px;
  transition: 0.5s ease;
}
.newsletter form .submit_btn:hover {
  background: #1e2d23;
  color: #fff;
}

span.icon-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #6e6e6e;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  border: 4px solid #fff;
  box-shadow: 0 0 7px 1px #1e2d237a;
  cursor: pointer;
}

span.icon-modal svg {
  margin: 0;
  padding: 0;
}

.about_us {
  padding: 130px 0;
}

.facts {
  background: #fcd804;
  padding: 70px 0;
  text-align: center;
  color: #fff;
}

.facts h4 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 50px;
  color: inherit;
}

.facts .facts_box {
  font-size: 54px;
  font-weight: 700;
  line-height: 52px;
}

.facts .facts_box span {
  display: block;
  font-size: 24px;
}

.facts .facts_box .facts_counter {
  display: inline;
}

.prds_pagination_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f4;
  padding: 5px 25px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #1e2d23;
  font-weight: 400;
  z-index: 1;
  position: relative;
}

.prds_pagination_info .right {
  background-color: transparent;
}

.prds_pagination_info p {
  margin: 0;
  font-size: 16px;
  color: #1e2d23;
  padding: 10px 10px;
  cursor: pointer;
  line-height: normal;
}

.prds_pagination_info .slider__options {
  bottom: auto;
  background: #fff;
  border: 1px solid #cccccc7d;
  border-radius: 4px;
  position: absolute;
  right: 0;
}

.prds_pagination_info .slider__options ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.prds_pagination_info .slider__options ul li {
  width: 150px;
  background: transparent;
  /* border-bottom: 1px solid #cccccc38; */
  padding: 10px 15px;
  font-size: 15px;
  cursor: pointer;
}

/* Paginataion Start*/
.pagination {
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}

.paginataion_button:hover {
  background: #005b36;
  color: #ffff;
}

.paginataion_button {
  border-radius: 3px;
  background: transparent;
  border: none;
  background: #fcd804;
  color: #fff;
  margin-left: 15px;
  font-size: 0;
  position: relative;
  text-transform: uppercase;
  padding: 8px 13px;
  line-height: normal;
  font-weight: 500;
  transition: 0.5s ease-in;
}

.paginataion_button:first-child:before {
  content: "Previous";
  font-size: 16px;
}

.paginataion_button:last-child:before {
  content: "Next";
  font-size: 16px;
}

.paginataion_button:first-child {
  margin-left: 0;
}

/*Paginataion End*/

/*Modal Css Start*/
body .modal {
  padding-top: 80px;
}

body .modal .modal-body {
  padding: 25px;
}

body .modal .modal-content {
  border: none;
  padding: 0;
  border-radius: 10px;
}

body .modal-header {
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 10;
  text-align: center;
  overflow: hidden !important;
  border-radius: 0 !important;
}

body .modal-header span.close {
  margin: 0 0 0 auto !important;
  padding: 0;
  background: #005b36;
  color: #fff;
  text-shadow: none;
  opacity: 1;
  font-size: 40px;
  height: 40px;
  width: 40px;
  line-height: 35px;
  border-radius: 0px 9px  !important;
  cursor: pointer;
}

body .modal-header span.close:hover {
  opacity: 1 !important;
}

body .modal .modal-title {
  font-size: 20px;
  line-height: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  color: #1e2d23;
  padding-bottom: 20px;
}
.modal-dialog {
  width: 100% !important;
}
@media (min-width: 576px) {
  body .modal .modal-dialog {
    max-width: 550px;
  }
}

/*Modal Css End*/

/*Product Card Start*/
.all_products .content .col-6 {
  margin-bottom: 30px;
}
.product-card {
  padding: 16px;
  position: relative;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 14%);
  cursor: pointer;
  height: 100%;
}
.service-card {
  padding: 16px;
  position: relative;
  margin-bottom: 45px;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 14%);
  cursor: pointer;
}
select {
  padding: 0.375rem 1.5rem !important;
}
.product-card .img-outer img.product_img {
  object-fit: cover;
  /* max-width: 100%; */
  width: 100%;
  height: 200px;
}

.product-card .name {
  font-size: 17px;
  line-height: normal;
  font-weight: 600;
  color: #1e2d23;
  margin-top: 25px;
}

.product-card .name a {
  color: inherit;
}

.product-card .price {
  color: #fcd804;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 8px;
  position: relative;
}

.product-card .img-outer {
  position: relative;
}

.product-card .img-outer .time {
  padding: 2px 10px;
  background: #fcd804;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 10px;
  right: 10px;
  text-transform: uppercase;
}

/*Product Card End*/
.all_products {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-bottom: 120px;
  position: relative;
}

.all_products .filters {
  position: relative;
  width: 25%;
}

.all_products .filters .filter_box {
  background: #f4f4f4;
  padding: 25px 25px 15px;
  margin-bottom: 20px;
}

.all_products .filters .filter_box h5 {
  font-size: 26px;
  font-weight: 700;
  color: #1e2d23;
  margin-bottom: 15px;
}

.all_products .content {
  width: 75%;
  padding-left: 30px;
}

.all_products .filters .category__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.all_products .filters .subCat {
  transform: scaleY(0);
  height: 0px;
  transition: all 2s ease-in-out;

  /* transform: scaleY(0px);
    transition: all 2s ease-in-out; */
  /* animation : slide 1s ease forwards; */
}

.all_products .filters .sub {
  animation: slide 0.3s ease forwards;
}

@keyframes slide {
  from {
    transform: scaleY(0);
    height: 0px;
  }

  to {
    transform: scaleY(150px);
    height: 80%;
  }
}

.all_products .filters .category_list {
  padding-left: 10px;
}

.all_products .filters .category_list li {
  padding: 7px 0;
  font-size: 16px;
  font-weight: 400;
  color: #737373;
  list-style: none;
  cursor: pointer;
  text-transform: capitalize;
}

.all_products .filters .category__header > h3 {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  color: #1e2d23;
  cursor: pointer;
  position: relative;
}

.all_products .filters .category__header .dropdown {
  margin-top: -8px;
}

.all_products .filters .category_list li:hover {
  transition: all 0.3s;
  font-weight: 900;
  color: #fcd804;
}

.map {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 100px;
  margin-top: 80px;
  height: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* .main-product {
    padding: 100px 0;
    } */

.container {
  width: 100% !important;
}

.error {
  color: red;
  font-size: 16px;
}

/* h3 {
    font-size: 1.3rem !important;
    } */


.image-gallery-thumbnails button.image-gallery-thumbnail {
  border: 2px solid transparent;
}

.image-gallery-thumbnails button.image-gallery-thumbnail {
  border: 2px solid transparent;
}

.image-gallery-thumbnails button.image-gallery-thumbnail.active,
.image-gallery-thumbnails button.image-gallery-thumbnail:hover,
.image-gallery-thumbnails button.image-gallery-thumbnail:focus {
  border: 2px solid #005b36;
}

.image-gallery-slide-wrapper.left button.image-gallery-icon svg {
  height: 75px;
  width: 60px;
}

.image-gallery-slide-wrapper.left button.image-gallery-icon:hover {
  color: #fff;
}

.image-gallery-slide-wrapper.left
  button.image-gallery-icon.image-gallery-fullscreen-button
  svg {
  height: 36px;
  width: 36px;
}

.quality_form_outer{
  margin:20px 0;
}

.related_products {
  margin: 50px auto;
}

.btn-outline-secondary {
  border-color: #f3f3f3 !important;
  background-color: #f3f3f3 !important;
  color: #000000 !important;
}

.display-3 {
  font-size: 2rem !important;
}

.display-2 {
  color: #265b37;
  font-weight: 600;
  font-size: 6rem !important;
}

.error_page p {
  margin-bottom: 10px;
}

.side_bar {
  color: #000 !important;
  background: #ffffff !important;
  box-shadow: 0px 1px 1px 0px rgb(68 102 242 / 45%) !important;
}

.side_bar .c-sidebar-brand {
  background-color: #ffffff !important;
  padding: 20px 20px;
  box-shadow: -3px 3px 7px 1px rgb(68 102 242 / 10%) !important;
}

ul.c-sidebar-nav.h-100.ps {
  list-style: none;
  margin: 0;
  padding: 0;
  /* background: #cccccc24; */
  background: #fff !important;
}
.change_phone_number {
  color: #005b36;
  font-size: 12px;
  cursor: pointer;
}

ul.c-sidebar-nav .c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
  font-size: 16px;
  letter-spacing: 0;
  text-transform: capitalize;
  font-weight: 600;
  color: #005b36;
  display: flex;
  align-items: center;
  padding: 15px 30px !important;
  transition: 0.3s;
}
.side_bar {
  width: 300px !important;
}

ul.c-sidebar-nav
  li.c-sidebar-nav-dropdown
  a.c-sidebar-nav-dropdown-toggle::after {
  filter: invert(1);
}

ul.c-sidebar-nav
  li.c-sidebar-nav-dropdown
  a.c-sidebar-nav-dropdown-toggle:hover::after {
  filter: inherit;
}

ul.c-sidebar-nav li.c-sidebar-nav-dropdown a.c-sidebar-nav-dropdown-toggle {
  color: #005b36;
}

.c-active {
  background-color: #005b36 !important;
  color: #ffffff !important;
}

ul.c-sidebar-nav .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #ffffff !important;
  background: #005b36 !important;
}

.c-sidebar-nav-dropdown.c-show ul.c-sidebar-nav-dropdown-items {
  display: block;
}

.c-sidebar-nav-dropdown-items {
  padding: 10px 0 0 0 !important;
  max-height: 100% !important;
  display: none;
}

ul.c-sidebar-nav .c-sidebar-nav-dropdown-items a.c-sidebar-nav-link {
  padding: 15px 30px 15px 62px !important;
  color: #005b36;
}

.c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
  padding: 1rem 0;
}

svg.c-icon {
  margin-right: 7px;
  width: 21px !important;
  height: 21px !important;
}

.c-wrapper {
  min-height: 0!important;
  margin-left: 320px!important;
  overflow: hidden;
  margin-right: 30px;
}
.scroll_div {
  overflow-y: scroll;
  scrollbar-width: none;
  overflow-x: hidden;
  height: 85vh;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: transparent !important;
}

.headingWrap {
  display: flex;
  align-items: center;
  margin: 0px auto 20px auto;
}

.headingWrap h3.title {
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  color: #1e2d23;
}

.myproduct .handleGrid {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  align-content: center;
  align-items: center;
  padding: 10px 0px;
  transform: rotate(0.15deg);
  vertical-align: middle;
  padding-top: 20px;
  grid-gap: 30px;
  padding-bottom: 20px;
}

.dashboardtabs .tabs .nav-tabs .nav-link.active {
  border-bottom: 4px solid #005b36 !important;
  background: transparent;
  border: none;
  color: #fcd804 !important;
}

.dashboardtabs .tabs .nav-tabs .nav-item .nav-link {
  padding: 10px 80px;
  font-size: 20px;
  transition: none;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  margin-bottom: -1px !important;
  border: none;
}

.dashboardtabs .tabs .nav-tabs .nav-item {
  margin: 0 !important;
}

.dashboardtabs .tabs .nav-tabs .nav-item .nav-link:hover,
.dashboardtabs .tabs .nav-tabs .nav-link.active:hover,
.dashboardtabs .tabs .nav-tabs .nav-link.active:focus-visible,
.dashboardtabs .tabs .nav-tabs .nav-item .nav-link:focus-visible {
  border: none;
}

/* Contract view Page css */
.contracts p {
  font-size: 16px;
  padding: 10px 0px;
  margin: 0 0 10px 0;
  line-height: normal;
  letter-spacing: normal;
  color: #1e2d23;
  display: grid;
  grid-template-columns: 3fr 10fr;
}

.contracts p span {
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  letter-spacing: normal;
  color: #005b36;
  margin-left: 30px;
  text-transform: capitalize;
}

.contracts h4 {
  color: #fcd804;
  margin-bottom: 30px;
  font-size: 18px;
}

.contract-gird {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.action_btn {
  color: #f5ac41;
  padding: 2px 20px !important;
  border: 1px solid #f5ac41 !important;
  border-radius: 5px;
}

.icon-size {
  font-size: 2.5rem !important;
}

.notification_ul {
  padding: 0 0 0 10px !important;
  margin: 0;
}

.notification_ul li {
  list-style: auto;
  font-size: 16px;
  cursor: pointer;
  padding: 17px 0;
  color: #1e2d23;
}

.notification_ul .normal {
  font-weight: normal;
}

.notification_ul .bold {
  font-weight: bold;
}

.notification_grid:last-child {
  border: none;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.dashboard-page .card-footer {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.dashboard-page .card-footer {
  text-align: right;
}

.dashboard-page .paginataion_button {
  /* border-radius: 50%;
    width: 30px;
    height: 30px; */
  color: #fcd804;
  border: 1px solid #fcd804;
  background: transparent;
  margin-left: 15px;
}

.dashboard-page .paginataion_button:first-child {
  margin-left: 0;
}

.dashboard-page .active {
  background-color: #fcd804;
  color: #ffffff;
}

.notification_grid {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;
  align-items: center;
}

.testTables .tableDataRow .tableData {
  color: #1e2d23 !important;
}

body .TablePagination {
  position: fixed;
  top: inherit;
  right: 20px;
  margin-top: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  background: #fff;
  bottom: 0;
  padding: 20px;
  width: 100%;
  left: 0;
}
.btn.btn-light {
  border-color: #fcd804;
  color: #fcd804;
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: 0.5s ease-in;
  width: auto;
  min-width: auto;
}

.btn.btn-light:hover {
  background: #fcd804;
  color: #fff;
}

body .btn.btn-secondary {
  border-color: #1e2d23c9;
  color: #fff;
  background-color: #1e2d23c9;
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: 0.5s ease-in;
  width: auto;
  min-width: auto;
}

body .btn.btn-secondary:hover {
  background-color: #fff;
  color: #1e2d23c9;
}

.buyer_seller_cont_outer .right p,
.other_sec p {
  white-space: pre-line;
}

.buyer_seller_cont_outer .left img {
  width: 100%;
}

.modal-header {
  border-bottom: none !important;
}

.dashboard-container {
  height: 100vh;
}
body .dash_edit_card {
  border: none;
  margin: 0;
}
body .dash_edit_card label {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: #1e2d23;
  margin-bottom: 10px;
  /* text-transform: capitalize; */
}
.index_product_image {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 225px;
}
.add-btn,
.remove-btn {
  font-size: 2rem;
}

body .dash_edit_card .add-btn {
  border: 2px solid #1e2d23c9;
  color: #1e2d23c9;
  width: 40px;
  height: 40px;
  font-size: 30px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

body .dash_edit_card .remove-btn,
body .dash_edit_card .remove-btn:hover {
  border-color: #1e2d23c9;
  color: #fff;
  background-color: #1e2d23c9;
  width: 40px;
  height: 40px;
  font-size: 30px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

body .dash_edit_card .form-control {
  border-radius: 0 !important;
  border: 1px solid #bfbfbf;
  height: auto !important;
  padding: 15px 15px !important;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  transition: 0.5s ease;
  color: #1e2d23;
  background: transparent !important;
}

body .dash_edit_card .form-control:focus {
  border-color: #005b36;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: #ffffff !important;
  color: #1e2d23 !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #1e2d23 !important;
}

body .dash_edit_card button.ant-btn {
  background: transparent;
  border: none;
  display: flex;
  box-shadow: none;
  font-weight: 600;
}

select#product_code {
  background: white;
}

.dropzoneStyle {
  width: "100%";
  height: "auto";
  border-width: 2;
  border-color: "rgb(102, 102, 102)";
  border-style: "dashed";
  /* borderRadius: 5, */
}

.ant-col.ant-col-10 {
  max-width: 100% !important;
}

.ant-row {
  width: 100%;
  margin: auto;
  text-align: center;
  display: block !important;
}

.ant-col.ant-col-14 {
  display: none;
}

.ant-card.ant-card-bordered {
  width: 100%;
}

.dash_product_details h1 {
  font-size: 30px;
  line-height: normal;
  font-weight: 600;
  color: #1e2d23;
  margin-bottom: 25px;
  word-break: break-all;
  width: 75%;
}

.dash_product_details ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dash_product_details ul li {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #1e2d23;
  line-height: normal;
}

.dash_product_details ul li img {
  max-width: 20px;
  margin-right: 20px;
}

.dash_product_details .price {
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 400;
  color: #1e2d23;
  line-height: normal;
}

.dash_product_details .price span {
  display: block;
  color: #fcd804;
  font-size: 22px;
  font-weight: 700;
  margin-top: 10px;
}

.dash_product_details h3 {
  font-size: 25px;
  line-height: normal;
  font-weight: 600;
  color: #1e2d23;
  margin-bottom: 10px;
}

.max_w_100 {
  max-width: 100%;
}
.max_w_70 {
  max-width: 70%;
}
.dash_payment {
  width: 50%;
}

.payment img {
  width: 100%;
  height: 100%;
}

.accountDetail {
  display: grid;
  grid-template-columns: 1fr 1fr 0fr 0fr;
  grid-gap: 20px;
}

.accountDetail .icons {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.cardDetail_div {
  margin-top: 70px;
}

.add_new p {
  text-decoration: underline;
  color: #fcd804;
  font-size: 1.2rem;
  font-weight: 800;
  cursor: pointer;
  text-decoration-thickness: 0.1em;
  text-decoration-thickness: 3px;
  margin-left: 100px;
}

.plus_icon {
  font-size: 7rem;
  font-weight: 400;
  text-align: center;
  display: block;
  cursor: pointer;
}

.image-size {
  width: 400px;
  height: 400px;
}

.order-detail-grid {
  padding: 80px 0 !important;
}

.order-detail-grid h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #1e2d23;
  border-bottom: 1px solid #d8dbe0 !important;
  padding-bottom: 20px;
}

.order_summary_sidebar {
  position: relative;
}

.order_summary_sidebar h4 {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
}

.order_summary_sidebar p {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
}

.order_summary_sidebar p span {
  display: block;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 100;
}

.order_summary_sidebar h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin: 20px 0 50px 0;
  color: #1e2d23;
  border-top: 1px solid #d8dbe0 !important;
  padding-top: 20px;
}

.product-grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  gap: 30px;
}

.order-detail-grid .address_detail h5 {
  margin-bottom: 10px !important;
  font-size: 16px;
  line-height: normal;
  text-transform: capitalize;
  padding: 8px 0;
}

.order-detail-grid .address_detail h5 strong {
  display: inline-block;
  font-weight: 700;
  min-width: 115px;
}

.order-detail-grid .address_detail h5 span {
  font-weight: 100;
}

li.c-header-nav-items.nav-item a {
  color: #1e2d23;
  text-decoration: none;
}
.grid-address {
  border: 1px solid #e3e5e5;
  padding: 20px;
  border-radius: 0;
  max-width: 350px;
  flex-direction: row;
}
.grid-address p {
  margin-bottom: 0 !important;
  font-size: 16px;
  line-height: normal;
  text-transform: capitalize;
  padding: 10px 0;
  width: 100%;
}
.border-bottom {
  border-bottom: 4px solid #fcd804;
  padding-bottom: 20px;
}
.address .border-outer {
  border: 1px solid #b9b9b9;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.address .border-outer .action-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 10px;
}

.step-process {
  padding: 30px 0;
}

.step-process .ant-steps-navigation .ant-steps-item::before {
  background-color: #fcd804;
}

.step-process
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #fcd804;
  border-color: #fcd804;
}

.step-process .ant-steps-item-icon {
  border-color: #fcd804 !important;
  color: #fcd804;
}

.steps_card_info {
  /* width: 50%; */
  padding: 30px 0;
}

.steps_card_info .card_box {
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.1) 33%,
    rgba(0, 0, 0, 0.12) 33% 66%,
    rgba(0, 0, 0, 0.14) 66% 100%
  );
  padding: 20px;
  border-radius: 10px;
  max-width: 320px;
  position: relative;
}

.steps_card_info .card_box img {
  max-width: 32px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.steps_card_info .card_box .card_type {
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
  line-height: normal;
  font-size: 25px;
  text-align: right;
}

.steps_card_info .card_box .card_number {
  text-align: center;
  color: #1e2d23;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 3px;
  height: 90px;
  display: flex;
  -webkit-align-items: center;
  justify-content: center;
}

.steps_card_info .card_box .btn.cstm_btn {
  background: #383838;
}

.steps_card_info .card_box .btn.cstm_btn:hover {
  background: #005b36;
}

.steps_card_info .btn.btn-outline-info {
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: 0.5s ease-in;
  border-color: #005b36;
  color: #005b36;
}

.steps_card_info .btn.btn-outline-info:hover {
  border-color: #005b36;
  background: #005b36;
  color: #fff;
}

.btn.btn-outline-danger:hover {
  border-color: #ff573d;
  color: #fff;
  background: #ff573d;
}

.btn.btn-outline-danger {
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: 0.5s ease-in;
  border-color: #ff573d;
  color: #ff573d;
}

.steps-content {
  margin-top: 40px;
}

.steps_login {
  width: 50%;
  margin-top: 30px;
}

.steps_login h6 {
  font-size: 16px;
  margin-bottom: 10px;
}

body .steps-content h3,
body .dash_payment h3.title {
  font-size: 20px !important;
  font-weight: 600;
  text-transform: none !important;
  margin: 0;
  color: #1e2d23;
}

.steps-content h5 {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  color: #1e2d23;
}

.step-process .ant-steps-item-icon span.ant-steps-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  color: #fcd804;
  line-height: normal;
}

.step-process .ant-steps-item-icon svg {
  fill: #fcd804;
}

.address .action_button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;
}

.grid-address .add_new_address {
  color: #005b36 !important;
  border: 2px solid #005b36;
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  display: block;
  transition: 0.5s ease-in;
  text-align: center;
  margin-top: 20px;
}

.grid-address .add_new_address:hover {
  background: #005b36;
  color: #fff !important;
}

.order-card {
  height: 350px !important;
}

.order-card .card-header,
.order-card .card-body {
  width: 100% !important;

  padding: 20px 20px;
}

.checkbox_label {
  color: #9a979a;
}

.address_detail h5 {
  line-height: 30px;
  word-spacing: 5px;
}

.rating_profile {
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
  border-radius: 50em;
}
.react-star .react-stars-wrapper {
  display: flex;
  justify-content: center !important;
}
.reactStar {
  margin: auto;
}

.create-offer {
  padding: 40px 0px;
}

.create-offer .form-group .form-control {
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  height: 55px;
  padding: 0 25px;
  font-size: 17px;
  line-height: inherit;
  font-weight: 400;
  transition: 0.5s ease;
  background: transparent;
}

.create-offer .heading {
  font-size: 50px;
  line-height: 56px;
  font-weight: 700;
  color: #1e2d23;
  margin-bottom: 15px;
}

/* Chrome, Safari, Edge, Opera */
.create-offer input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.create-offer input[type="number"] {
  -moz-appearance: textfield;
}

.img-size {
  width: 25px;
  height: 25px;
}

.oferView {
  padding: 10px 0;
}

.oferView {
  display: grid;
  grid-template-columns: 2fr 2fr;
}

.offerDetail h1 {
  font-weight: 500;
  color: #fcd804;
}

.oferView img {
  margin: auto;
  width: 500px;
  height: 500px;
}

.offerDetail {
  margin: auto 0;
}

.offerDetail .accept_now {
  padding: 11px 60px;
  margin: 30px 0;
  background-color: #000000;
  color: #ffffff !important;
  font-weight: 600;
}

.offerDetail .accept_now:hover {
  color: #000000 !important;
  border: 1px solid #000000;
  font-weight: 800;
  background-color: transparent;
}

.offerDetail .make_button {
  padding: 11px 60px;
  margin: 30px 20px;
  background-color: #fcd804;
  color: #ffffff !important;
  font-weight: 600;
}

.offerDetail .make_button:hover {
  border: 1px solid #fcd804;
  color: #fcd804 !important;
  background: transparent;
  font-weight: 800;
}

.offerDescription {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
}

.offerDescription .heading {
  margin: 20px 0;
}

.offer-item {
  display: grid;
  grid-template-columns: 0fr 1fr;
  grid-gap: 40px;
  margin: 10px 0;
}

.offer-item p {
  margin: 0;
}

.offer-item svg {
  margin: auto;
  display: flex;
}

.offerDetail h2 {
  color: #fcd804;
  margin: 20px 0;
}

.service_modal {
  height: 300px;
  text-align: center;
}

.service_modal .user_name {
  text-align: center;
  margin: 20px;
}

.react-star {
  padding: 0px 20px;
}

.reset-icon {
  width: 18px;
  cursor: pointer;
  display: inline-flex;
  margin-left: 15px;
}

.doneIt {
  position: absolute;
  float: left;
  right: 30px;
  z-index: 1;
  background: #f3f3f3;
}

.doneIt .rangeButton {
  padding: 20px;
}

.doneIt .rangebtn {
  padding: 10px 40px;
  display: grid;
  margin-left: auto !important;
  margin: 10px;
  border: 3px solid #fcd804;
  border-radius: 50px;
  color: #fcd804;
  font-size: 1.1rem;
  font-weight: 600;
  transition: 0.3s;
}

.doneIt .rangebtn:hover {
  background: #fcd804;
  color: #f3f3f3;
  font-weight: 700;
}

.not-found {
  font-size: 1.2rem;
  margin: 230px auto;
  text-align: center;
  color: #fcd804;
  font-weight: 900;
}

.contract-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

/*Profile.css start*/
.profile-page {
  position: relative;
}

.card-profile {
  padding-top: 120px;
}

.card-profile-image {
  position: relative;
}

.profile-page #photo-upload {
  display: none;
}

.card-profile-image:hover .edit-icon {
  display: block;
}

.card-profile-image {
  position: absolute;
  left: 50%;
  max-width: 180px;
  transition: all 0.15s ease;
  transform: translate(-50%, -30%);
  border-radius: 0.375rem;
  margin: auto;
  display: flex;
  justify-content: center;
}

.edit-icon {
  padding-top: 7px;
  padding-right: 7px;
  position: absolute;
  right: 100px;
  top: -50px;
  display: none;
  background: #fff;
  border-radius: 50px;
  padding: 7px;
  cursor: pointer;
}

.card-profile-image img:hover {
  transform: translate(-50%, -33%);
}

.profile-page #country_code {
  width: 33%;
  font-size: 11px;
  padding: 10px;
  height: 35px;
  background: #fff !important;
}

.profile-page p {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0px;
  margin: 10px 0px;
  line-height: normal;
  color: #1e2d23;
  text-align: left;
  display: grid;
  grid-template-columns: 3fr 10fr;
}
.profile-page p span {
  color: #adadad;
  padding-left: 20px;
}

.field {
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.profile-page #photo-upload {
  display: none;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #3fa1a9, #79f1a4);
  margin-bottom: 25px;
}

.img-wrap {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.img-upload:before {
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}

.img-upload:hover:before {
  opacity: 1;
}

.profile-page img {
  width: auto;
  height: 100%;
}

.thankyou {
  padding: 80px 30px;
}

.thankyou img {
  width: 500px;
  height: 350px;
  margin: auto;
  display: flex;
}

.thankyou h3 {
  margin: 60px auto;
  /* color:#fcd804 !important; */
  font-size: 1.3rem;
  font-weight: 700;
}

.thankyou .verified :hover {
  color: #fcd804 !important;
  font-size: 1.3rem;
  font-weight: 700;
  text-decoration: underline !important;
  cursor: pointer;
}
.profile-page h6.heading-small {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
  margin-top: 20px;
  color: #1e2d23;
  border-bottom: 1px solid #d8dbe0 !important;
  padding-bottom: 10px;
  text-transform: capitalize;
}

/*Profile.css end*/
/*Login.css start*/

.login_signup .left img {
  max-width: 600px;
  width: 100%;
}

.login_signup .left {
  width: 50%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.login_signup .verify_form .form-group .form-control {
  text-align: center;
  letter-spacing: 15px;
  font-size: 18px;
}
.login_signup .right {
  width: 50%;
  max-width: 580px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 80px 40px;
}
.login_signup:before {
  content: "";
  background: url(../../../assets/images/login_bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
}
.login_signup .right p {
  color: #bfbfbf;
}
.login_signup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100vh;
}
.login_signup.only_signup {
  height: auto;
}
.login_signup .right .heading {
  text-transform: uppercase;
  margin-bottom: 50px;
  line-height: 50px;
  margin-top: 50px;
}
.login_signup .right .form-group label {
  font-size: 17px;
  color: #101e36;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: normal;
}

.login_signup .right .form-group .form-control {
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  height: 55px;
  padding: 0 25px;
  font-size: 17px;
  line-height: inherit;
  font-weight: 400;
  background-color: transparent;
  transition: 0.5s ease;
}

.login_signup .right .form-group .btn.cstm_btn {
  width: 100%;
  text-transform: capitalize;
  font-size: 18px;
  padding: 14px 0;
}

.login_signup .right h6 {
  margin: 25px 0 0 0;
  text-align: center;
  font-size: 17px;
  line-height: normal;
  font-weight: 400;
  color: #1e2d23;
  margin-bottom: 0;
}
.tabs.logintabs ul.nav.nav-tabs {
  border: 2px solid #005b36;
  display: flex;
}
.tabs.logintabs ul.nav.nav-tabs li.nav-item {
  margin: 0 !important;
  width: 50%;
  text-align: center;
}
.tabs.logintabs ul.nav.nav-tabs .nav-item a.nav-link {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  transition: none;
  cursor: pointer;
  margin: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  color: #005b36 !important;
}

.tabs.logintabs ul.nav.nav-tabs .nav-item a.nav-link.active {
  background: #005b36;
  color: #fff !important;
}
.country_code {
  width: 5%;
  position: absolute;
  font-size: 10px;
  padding: 21px;
}
.country-control {
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  height: 55px;
  padding: 0 25px;
  font-size: 17px;
  line-height: inherit;
  font-weight: 400;
  color: #1e2d23;
  transition: 0.5s ease;

  width: 96%;
  left: 0px;
}
.country_select {
  display: flex;
}
#country_code {
  width: 33%;
  padding: 0 10px;
}
.country_select select#country_code {
  margin-right: 20px;
}
.tabs.logintabs .tab-content {
  padding: 30px 0;
}
.logintabs .nav-tabs {
  cursor: pointer;
}
.resend_btn {
  cursor: pointer;
  font-size: 15px;
  margin: auto;
}
.resend_btn:hover {
  color: #fcd804 !important;
}

a.otp {
  float: right;

  text-decoration: underline;
  color: blue;
}
a.email {
  text-decoration: underline;
  color: blue;
}
a#phones {
  text-decoration: underline;
  color: blue;
}
.login_signup .right h6 a.head {
  margin-left: 2px;
}
button.otp.btn {
  margin-top: 25px;
  width: 100%;
}
.tabs.logintabs .btn.cstm_btn {
  width: 100%;
  text-transform: capitalize;
  font-size: 18px;
  padding: 14px 0;
  margin-top: 25px;
}
button.next_btn.btn {
  background: #fcd804;
  color: #fff;
  float: right;
  margin-top: 24px;
  margin: 6px;
}
button.resend_btn.btn {
  background: #fcd804;
  color: #fff;
  margin-top: 24px;
  margin: 6px;
}
.phone_label {
  font-size: 17px;
  color: #101e36;
  font-weight: 600;
  margin-bottom: 15px;
}
span.error {
  color: #e63946;
  font-size: 1rem;
  margin-top: 0.3rem;
}
h2.head {
  font-size: 40px;
  color: #1e2d23;
  text-transform: uppercase;
  margin-bottom: 20px;
  line-height: 50px;
  margin-top: 50px;
}
.head {
  color: #fcd804 !important;
  font-weight: 600;
}
.head:hover {
  color: #000 !important;
  font-weight: 600;
  text-decoration: none;
}
h2.change {
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 50px;
  font-size: 35px;
  line-height: 56px;
  font-weight: 700;
  color: #1e2d23;
}

/*Login.css End*/
.terms_link {
  color: #000000;
  text-decoration: none;
}
.terms_link:hover {
  color: #231698;
  text-decoration: none;
}
.service_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.btn.btn-outline-info {
  border-radius: 5px !important;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: 0.5s ease-in;
  border-color: #005b36 !important;
  color: #005b36;
  border: 1px solid #005b36 !important;
}
.filter_span {
  margin-top: 7px;
  margin-bottom: auto;
  margin-left: auto;
  cursor: pointer;
  color: #fcd804;
  letter-spacing: 0.5px;
}
.testimonials_carousel .owl-carousel.owl-stage {
  cursor: default;
}
.other_sec {
  margin: 80px auto;
}
.cursor_table table.testTables tbody tr.tableDataRow {
  cursor: pointer;
}
.goog-te-banner {
  display: none !important;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
.description_html ol {
  text-align: left !important;
}
.description_html ol li span:first-child {
  margin: 10px;
}

.responsive-iframe {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 700px;
}
.about_text .web_heading{
  top: -547px;
  position: absolute;
  width: 100%;
  right: 0;
  color: #ffff;
  padding-right: 30px;
}
.about_text .web-heading,.about_text h2, .about_text p{
  color:#fff
}
.scroll_div::-webkit-scrollbar {
  width: 0 !important;
}
.scroll_div {
  overflow: -moz-scrollbars-none;
}
/* -ms- (Internet Explorer +10): */
.scroll_div {
  -ms-overflow-style: none;
}
/* .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image{
    height:57vh !important;
    } */
.image-gallery-slide .image-gallery-image {
  object-fit: unset !important;
}
.fa-eye,
.fa-eye-slash {
  margin: 15px 0;
  position: absolute;
  right: 43%;

  cursor: pointer;
}
.image-gallery-slide.center {
  margin: auto;
  display: flex;
  align-items: center;
}

/*-----------------------------05-10-2021--------------------------*/
.topbar .col_6.col-md-6:last-child,
.topbar .right_side .col-lg-6 {
  padding-right: 0;
}
.service_provider_box h3.text-center {
  margin-top: 15px;
}
header #search {
  max-width: 725px;
  margin-left: auto;
  margin-right: auto;
}
.navbar-nav > li.nav-item a.nav-link {
  padding: 11px 18px;
}
.newsletter .form-group {
  margin-bottom: 0;
}
.right_side select {
  width: 150px;
  padding-left: 15px !important;
}
.topbar .right_side .col-lg-6 {
  width: auto;
}
.about_sec .img_outer iframe {
  width: 100%;
  position: absolute;
  left: 0;
  height: 100%;
}
.about_sec .img_outer > div {
  padding-bottom: 56.25%;
  position: relative;
}
.service_provider_details_cont.main-product .side_add_cont {
  right: 0;
  width: 20%;
  gap: 0;
  max-width: initial;
}
.container.position-relative .side_add_cont img {
  max-width: 100%;
}
.filter_box ul {
  padding: 0;
  margin: 8px 0;
}
.collection-otr > .row {
  margin: 0;
}
.image-gallery-slide-wrapper.left {
  padding-left: 10px;
}
.related_products .col-6 {
  margin: 15px 0;
}
.related_products .row {
  justify-content: center;
}
.login_signup .right form#email_form h6 {
  margin-top: 10px;
}

/*-------------------------------------dashboard--------------------------------------------*/
.c-header-nav-item.dropdown {
  margin: 0 !important;
}
button.c-header-nav-link.btn {
  padding: 0;
}
.c-header-nav .form-group.focused.m-0 {
  margin-right: 20px !important;
}
.navbar.navbar-dark .navbar-toggler-icon {
  background-image: url("../../../assets/images/hamburger.png") !important;
}
body .header {
  padding-left: 30px;
}
.notification_ul li span.float-right {
  float: none !important;
  display: inline-block;
}
span.pageCount {
  display: flex;
  align-items: center;
}
body .TablePagination .pageInput {
  padding: 2px 7px;
}
body .dash_edit_card .form-group .d-flex {
  position: relative;
}
body .dash_edit_card .form-group i.fa-eye-slash {
  right: 10px;
  margin: 18px 0;
}
.card-profile h3 {
  margin-top: 15px;
}
.steps_card_info {
  padding-top: 0;
}
.steps_card_info > div {
  padding-right: 20px;
}
.headingWrap {
  margin-top: 1.5rem;
}
.headingWrap h3.m-4.title {
  margin: 0 !important;
}
.testTables .tableHeadRow .tableHead,
.testTables .tableDataRow .tableData {
  padding: 14px 20px !important;
}
.sidebar_naviagtion {
  z-index: 1;
  cursor: pointer;
}
body .testTables .tableDataRow .tableData button {
  padding: 0px 10px;
}
body .testTables .tableDataRow .tableData .button_complete {
  padding: 7px 10px;
  color: #fff;
font-weight: bolder;
background-color: #fbb83d;
}
span.ant-upload-picture-card-wrapper {
  margin-bottom: 20px;
}
.c-wrapper.dashboard-container .form-group.float-right button {
  padding: 10px 20px;
}
.card.service-card .col-md-6 {
  margin-bottom: 1rem;
}
.messages_div .col-8 {
  height: 88vh !important;
}
.chat_list {
  /* height: 650px; */
  /* box-shadow: inset -2px -2px 3px 0px rgb(0 0 0 / 12%); */
  box-shadow: 3px 2px 3px 0px rgb(68 102 242 / 5%);
}
.md-block.chat_view {
  height: 89vh;
  /* border-left: 1px solid black ; */
}

#background {
  position: absolute;
  z-index: 0;
  background: white;
  display: block;
  min-height: 50%;
  min-width: 50%;
  color: yellow;
  top: 50%;
  left: 50%;
}

#content {
  position: absolute;
  z-index: 1;
}

#bg-text {
  color: #e3e5e5;
  font-size: 4rem;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.cs-message-list {
  height: calc(100vh - 240px) !important;
}
@media (max-width: 756px) {
  .chat_show {
    display: none;
  }
}
.sidebar_naviagtion {
  height: 100vh;
  overflow: scroll;
  /* position: relative; */
  display: block;
  scrollbar-width: none;
}
ul.c-sidebar-nav.h-100.ps {
  height: 100vh !important;
  overflow: scroll !important;
  display: block;
  scrollbar-width: none;
}

.sidebar_naviagtion::-webkit-scrollbar {
  width: 0 !important;
}
.sidebar_naviagtion {
  overflow: -moz-scrollbars-none;
}

.thankyou_img {
  width: 500px;
  height: 500px;
  display: flex;
  margin: auto;
}
.padding-x {
  padding: 100px 0;
}
.navbar-toggler p {
  margin: 0;
  font-size: 14px;
}
.input-group-calender {
  max-width: 225px;
  padding-left: 15px;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}
.react-datepicker__header {
  background-color: #ffffff !important;
  border-bottom: 1px solid #ffffff !important;
  padding-top: 16px !important;
}
button.btn.btn-outline-secondary {
  background-color: transparent !important;
  border: none !important;
}
.input-group-calender {
  max-width: 241px !important;
  padding-left: 0 !important;
}
.header p {
  margin: 0 !important;
}
.modal-open .modal {
  display: flex !important;
  align-items: center;
}
.backout_modal {
  width: 450px !important;
}
.tp-header .custom-select,
.custom-select option {
  text-transform: capitalize !important;
  width: 20% !important;
margin-right:20px;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #fcd802 !important;
}
.modal_card {
  padding: 50px 100px;
}
.paypal-checkout {
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  width: 100% !important;
}
.paypal-checkout div {
  width: 100% !important;
}
.paypal_Credit .modal-dialog {
  width: 100%;
}
.paypal_Credit .modal-dialog .modal-content .modal-body {
  height: 90vh;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
  justify-content: center;
}
.paypal_Credit .modal-dialog .modal-content .modal-body div {
  width: 100%;
}
.modal-open .modal {
  overflow-x: hidden;
  scrollbar-width: none;
  overflow-y: hidden !important;
}
.message-list {
  border-right: 1px solid #cccccc;
  height: 85vh !important;
  overflow-y: scroll;
  scrollbar-width: none;
  overflow-x: hidden;
}
.message-list::-webkit-scrollbar {
  display: none;
}
.input-container {
  position: absolute;
  bottom: 0;
  width: 98%;
  background-color: #ffffff;
}
iframe {
  margin: -11px 0;
}
.category_img {
  border-radius: 0 !important;
  max-width: 100px;
  min-width: 100px;
  margin-right: 22px;
  max-height: 100px;
  min-height: 100px;
  object-fit: cover;
  box-shadow: none !important;
}

/* card css starts from here */
.s-provider {
  padding: 40px 50px;
  background-color: #f9f9f9;
  box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
  border-radius: 14px;
}
.s-provider p{
  margin-top: 20px;
}
.sp-content{
  justify-content: space-between;
}
.img-title img{
  max-width: 38px;
}
.img-title h2{
  font-size: 24px;
  margin-bottom:  0;
}
.price{
  font-size: 18px;
  font-weight: 600;
  color: #005b36;
}
.product-message{
  color:red !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #fcd802 !important;
}
.modal-button {
  display: flex;
  justify-content: space-between;
  margin: 60px 0;
}
.user_detail {
  display: flex;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
}
img.user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.address .d-grid{
  grid-template-columns: 1fr 1fr 1fr;
}
.button_grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:10px;
}
.out_of_stock{
  color: red;
    font-weight: 600;
}
.fa-message, .fa-bell{
  color:#ffffff;
}
.user_image{
  width: 40px;
  height:40px;
  border-radius:50%;
}
.latest_product_image{
  height:250px;
}
.author{
  width: 40px !important;
  height: 40px;
}
.testimonial-back .owl-dots{
  display: none;
}