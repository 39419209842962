.modal-button .btn-primary {
  padding: 11px 25px;
  background: #fcd804;
  color: #fff !important;
  border-radius: 5px;
  text-transform: uppercase;

}

.modal-button .btn-light {
  padding: 11px 25px;
  border-color: #fcd804;
  color: #fcd804;
  border-radius: 5px;
  text-transform: uppercase;
}

.icon_wrap {
  width: 80px;
  height: 80px;
  border-radius: 7px;
  display: flex;
  margin: 40px auto;
}